'use client';

import React, { createContext, useContext, useRef } from 'react';
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

import type { MediaDevicesStore } from './media-devices-store.types';

export const createMediaDevicesStore = () => {
  return createStore<MediaDevicesStore>((set) => ({
    deviceErrors: { audio: null, video: null },
    setDeviceErrors: (errors) => set((state) => ({ deviceErrors: { ...state.deviceErrors, ...errors } })),
  }));
};

export type MediaDevicesStoreApi = ReturnType<typeof createMediaDevicesStore>;

export const MediaDevicesStoreContext = createContext<MediaDevicesStoreApi | null>(null);

export interface MediaDevicesStoreProviderProps {
  children: React.ReactNode;
}

export const MediaDevicesStoreProvider = ({ children }: MediaDevicesStoreProviderProps) => {
  const storeRef = useRef<MediaDevicesStoreApi>();

  if (!storeRef.current) {
    storeRef.current = createMediaDevicesStore();
  }
  return <MediaDevicesStoreContext.Provider value={storeRef.current}>{children}</MediaDevicesStoreContext.Provider>;
};

export const useMediaDevicesStore = <T,>(selector: (state: MediaDevicesStore) => T): T => {
  const store = useContext(MediaDevicesStoreContext);

  if (!store) {
    throw new Error('useMediaDevicesStore must be used within a MediaDevicesStoreProvider');
  }
  return useStore(store, selector);
};
