import * as DialogPrimitive from '@radix-ui/react-dialog';

import { styled } from '../../theme';

export const StyledOverlay = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 90,

  variants: {
    color: {
      default: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
      black: {
        backgroundColor: '#000000',
      },
    },
  },

  defaultVariants: {
    color: 'default',
  },
});

export const StyledContent = styled(DialogPrimitive.Content, {
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: 90,
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#101010',
  border: '1px solid #1E1E1E',
  borderRadius: 2,
  '&:focus': { outline: 'none' },
});

export const StyledClose = styled(DialogPrimitive.Close, {
  position: 'fixed',
  zIndex: 1,
  top: 16,
  right: 16,
  svg: {
    stroke: '#7D7D7D',
    transition: 'stroke 0.1s ease-in-out',
  },

  '&:hover': {
    svg: {
      stroke: '#FFFFFF',
    },
  },
});
