import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, useMutation, type UseQueryOptions, type UseInfiniteQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type RoomSectionDetailsFragment = (
  { readonly __typename?: 'rooms_sections' }
  & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
  & { readonly breakouts: ReadonlyArray<(
    { readonly __typename?: 'breakouts' }
    & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
    & { readonly room_section?: Types.Maybe<(
      { readonly __typename?: 'rooms_sections' }
      & Pick<Types.Rooms_Sections, 'room_id'>
    )> }
  )> }
);

export type ChannelsUserParticipantFragment = (
  { readonly __typename?: 'channels_users' }
  & Pick<Types.Channels_Users, 'channel_id' | 'user_id' | 'hand_raised' | 'hand_raised_updated_at' | 'connection' | 'volume' | 'force_muted'>
  & { readonly user: (
    { readonly __typename?: 'users' }
    & Pick<Types.Users, 'username' | 'photo' | 'role'>
  ) }
);

export type GetRoomRoomsSectionsQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
}>;


export type GetRoomRoomsSectionsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms_sections: ReadonlyArray<(
    { readonly __typename?: 'rooms_sections' }
    & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
    & { readonly breakouts: ReadonlyArray<(
      { readonly __typename?: 'breakouts' }
      & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
      & { readonly room_section?: Types.Maybe<(
        { readonly __typename?: 'rooms_sections' }
        & Pick<Types.Rooms_Sections, 'room_id'>
      )> }
    )> }
  )> }
);

export type GetAllRoomRoomsSectionsQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
}>;


export type GetAllRoomRoomsSectionsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms_sections: ReadonlyArray<(
    { readonly __typename?: 'rooms_sections' }
    & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
    & { readonly breakouts: ReadonlyArray<(
      { readonly __typename?: 'breakouts' }
      & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
      & { readonly room_section?: Types.Maybe<(
        { readonly __typename?: 'rooms_sections' }
        & Pick<Types.Rooms_Sections, 'room_id'>
      )> }
    )> }
  )> }
);

export type GetRoomSectionParticipantsQueryVariables = Types.Exact<{
  channelIds: ReadonlyArray<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
}>;


export type GetRoomSectionParticipantsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly channels_users: ReadonlyArray<(
    { readonly __typename?: 'channels_users' }
    & Pick<Types.Channels_Users, 'channel_id' | 'user_id' | 'hand_raised' | 'hand_raised_updated_at' | 'connection' | 'volume' | 'force_muted'>
    & { readonly user: (
      { readonly __typename?: 'users' }
      & Pick<Types.Users, 'username' | 'photo' | 'role'>
    ) }
  )> }
);

export type GetFullRoomBreakoutQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
  breakoutId: Types.Scalars['uuid']['input'];
}>;


export type GetFullRoomBreakoutQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms_by_pk?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & { readonly rooms_sections: ReadonlyArray<(
      { readonly __typename?: 'rooms_sections' }
      & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
      & { readonly breakouts: ReadonlyArray<(
        { readonly __typename?: 'breakouts' }
        & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
        & { readonly room_section?: Types.Maybe<(
          { readonly __typename?: 'rooms_sections' }
          & Pick<Types.Rooms_Sections, 'room_id'>
        )> }
      )> }
    )> }
  )> }
);

export type GetFullDefaultRoomBreakoutQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
}>;


export type GetFullDefaultRoomBreakoutQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms_by_pk?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & { readonly rooms_sections: ReadonlyArray<(
      { readonly __typename?: 'rooms_sections' }
      & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
      & { readonly breakouts: ReadonlyArray<(
        { readonly __typename?: 'breakouts' }
        & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
        & { readonly room_section?: Types.Maybe<(
          { readonly __typename?: 'rooms_sections' }
          & Pick<Types.Rooms_Sections, 'room_id'>
        )> }
      )> }
    )> }
  )> }
);

export type GetDefaultRoomBreakoutQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
}>;


export type GetDefaultRoomBreakoutQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms_by_pk?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & { readonly rooms_sections: ReadonlyArray<(
      { readonly __typename?: 'rooms_sections' }
      & { readonly breakouts: ReadonlyArray<(
        { readonly __typename?: 'breakouts' }
        & Pick<Types.Breakouts, 'id'>
      )> }
    )> }
  )> }
);

export type UserUpsertRoomsSectionMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  roomId: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
  iconImageUrl: Types.Scalars['String']['input'];
  imageUrl: Types.Scalars['String']['input'];
  sortOrder?: Types.Scalars['Int']['input'];
  color?: Types.InputMaybe<Types.Scalars['String']['input']>;
  type?: Types.InputMaybe<Types.Scalars['String']['input']>;
  platformSettings?: Types.InputMaybe<Types.Scalars['jsonb']['input']>;
}>;


export type UserUpsertRoomsSectionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly upsertRoomSection?: Types.Maybe<(
    { readonly __typename?: 'UpsertRoomSectionOutput' }
    & Pick<Types.UpsertRoomSectionOutput, 'message'>
    & { readonly errors?: Types.Maybe<(
      { readonly __typename?: 'UpsertRoomSectionOutputErrors' }
      & Pick<Types.UpsertRoomSectionOutputErrors, 'id' | 'roomId' | 'name' | 'imageUrl' | 'iconImageUrl' | 'sortOrder' | 'color' | 'type' | 'platformSettings'>
    )> }
  )> }
);

export type DeleteRoomSectionMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeleteRoomSectionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly delete_rooms_sections_by_pk?: Types.Maybe<(
    { readonly __typename?: 'rooms_sections' }
    & Pick<Types.Rooms_Sections, 'id'>
  )> }
);

export type UpdateRoomsSectionsSortOrderMutationVariables = Types.Exact<{
  updates: Types.Scalars['jsonb']['input'];
}>;


export type UpdateRoomsSectionsSortOrderMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly updateRoomsSectionsSortOrders?: Types.Maybe<(
    { readonly __typename?: 'UpdateRoomsSectionsSortOrdersOutput' }
    & Pick<Types.UpdateRoomsSectionsSortOrdersOutput, 'success' | 'message'>
  )> }
);


export const RoomSectionDetailsFragmentDoc = `
    fragment RoomSectionDetails on rooms_sections {
  id
  name
  image_url
  icon_image_url
  sort_order
  association_id
  room_id
  color
  type
  platform_settings
  created_at
  breakouts(order_by: {sort_order: asc, created_at: asc}) {
    id
    name
    type
    sort_order
    platform_settings
    room_section {
      room_id
    }
    channel_id
  }
}
    `;
export const ChannelsUserParticipantFragmentDoc = `
    fragment ChannelsUserParticipant on channels_users {
  channel_id
  user_id
  hand_raised
  hand_raised_updated_at
  connection
  volume
  force_muted
  user {
    username
    photo
    role
  }
}
    `;
export const GetRoomRoomsSectionsDocument = `
    query GetRoomRoomsSections($roomId: uuid!) {
  rooms_sections(where: {room_id: {_eq: $roomId}}, order_by: {sort_order: asc}) {
    ...RoomSectionDetails
  }
}
    ${RoomSectionDetailsFragmentDoc}`;

export const useGetRoomRoomsSectionsQuery = <
      TData = GetRoomRoomsSectionsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomRoomsSectionsQueryVariables,
      options?: UseQueryOptions<GetRoomRoomsSectionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRoomRoomsSectionsQuery, TError, TData>(
      ['GetRoomRoomsSections', variables],
      fetcher<GetRoomRoomsSectionsQuery, GetRoomRoomsSectionsQueryVariables>(client, GetRoomRoomsSectionsDocument, variables, headers),
      options
    )};

useGetRoomRoomsSectionsQuery.getKey = (variables: GetRoomRoomsSectionsQueryVariables) => ['GetRoomRoomsSections', variables];

export const useInfiniteGetRoomRoomsSectionsQuery = <
      TData = GetRoomRoomsSectionsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomRoomsSectionsQueryVariables,
      options?: UseInfiniteQueryOptions<GetRoomRoomsSectionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetRoomRoomsSectionsQuery, TError, TData>(
      ['GetRoomRoomsSections.infinite', variables],
      (metaData) => fetcher<GetRoomRoomsSectionsQuery, GetRoomRoomsSectionsQueryVariables>(client, GetRoomRoomsSectionsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetRoomRoomsSectionsQuery.getKey = (variables: GetRoomRoomsSectionsQueryVariables) => ['GetRoomRoomsSections.infinite', variables];


useGetRoomRoomsSectionsQuery.fetcher = (client: GraphQLClient, variables: GetRoomRoomsSectionsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRoomRoomsSectionsQuery, GetRoomRoomsSectionsQueryVariables>(client, GetRoomRoomsSectionsDocument, variables, headers);

export const GetAllRoomRoomsSectionsDocument = `
    query GetAllRoomRoomsSections($roomId: uuid!) {
  rooms_sections(where: {room_id: {_eq: $roomId}}, order_by: {sort_order: asc}) {
    ...RoomSectionDetails
  }
}
    ${RoomSectionDetailsFragmentDoc}`;

export const useGetAllRoomRoomsSectionsQuery = <
      TData = GetAllRoomRoomsSectionsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAllRoomRoomsSectionsQueryVariables,
      options?: UseQueryOptions<GetAllRoomRoomsSectionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAllRoomRoomsSectionsQuery, TError, TData>(
      ['GetAllRoomRoomsSections', variables],
      fetcher<GetAllRoomRoomsSectionsQuery, GetAllRoomRoomsSectionsQueryVariables>(client, GetAllRoomRoomsSectionsDocument, variables, headers),
      options
    )};

useGetAllRoomRoomsSectionsQuery.getKey = (variables: GetAllRoomRoomsSectionsQueryVariables) => ['GetAllRoomRoomsSections', variables];

export const useInfiniteGetAllRoomRoomsSectionsQuery = <
      TData = GetAllRoomRoomsSectionsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAllRoomRoomsSectionsQueryVariables,
      options?: UseInfiniteQueryOptions<GetAllRoomRoomsSectionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetAllRoomRoomsSectionsQuery, TError, TData>(
      ['GetAllRoomRoomsSections.infinite', variables],
      (metaData) => fetcher<GetAllRoomRoomsSectionsQuery, GetAllRoomRoomsSectionsQueryVariables>(client, GetAllRoomRoomsSectionsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetAllRoomRoomsSectionsQuery.getKey = (variables: GetAllRoomRoomsSectionsQueryVariables) => ['GetAllRoomRoomsSections.infinite', variables];


useGetAllRoomRoomsSectionsQuery.fetcher = (client: GraphQLClient, variables: GetAllRoomRoomsSectionsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAllRoomRoomsSectionsQuery, GetAllRoomRoomsSectionsQueryVariables>(client, GetAllRoomRoomsSectionsDocument, variables, headers);

export const GetRoomSectionParticipantsDocument = `
    query GetRoomSectionParticipants($channelIds: [uuid!]!) {
  channels_users(where: {channel_id: {_in: $channelIds}, status: {_eq: active}}) {
    ...ChannelsUserParticipant
  }
}
    ${ChannelsUserParticipantFragmentDoc}`;

export const useGetRoomSectionParticipantsQuery = <
      TData = GetRoomSectionParticipantsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomSectionParticipantsQueryVariables,
      options?: UseQueryOptions<GetRoomSectionParticipantsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRoomSectionParticipantsQuery, TError, TData>(
      ['GetRoomSectionParticipants', variables],
      fetcher<GetRoomSectionParticipantsQuery, GetRoomSectionParticipantsQueryVariables>(client, GetRoomSectionParticipantsDocument, variables, headers),
      options
    )};

useGetRoomSectionParticipantsQuery.getKey = (variables: GetRoomSectionParticipantsQueryVariables) => ['GetRoomSectionParticipants', variables];

export const useInfiniteGetRoomSectionParticipantsQuery = <
      TData = GetRoomSectionParticipantsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomSectionParticipantsQueryVariables,
      options?: UseInfiniteQueryOptions<GetRoomSectionParticipantsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetRoomSectionParticipantsQuery, TError, TData>(
      ['GetRoomSectionParticipants.infinite', variables],
      (metaData) => fetcher<GetRoomSectionParticipantsQuery, GetRoomSectionParticipantsQueryVariables>(client, GetRoomSectionParticipantsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetRoomSectionParticipantsQuery.getKey = (variables: GetRoomSectionParticipantsQueryVariables) => ['GetRoomSectionParticipants.infinite', variables];


useGetRoomSectionParticipantsQuery.fetcher = (client: GraphQLClient, variables: GetRoomSectionParticipantsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRoomSectionParticipantsQuery, GetRoomSectionParticipantsQueryVariables>(client, GetRoomSectionParticipantsDocument, variables, headers);

export const GetFullRoomBreakoutDocument = `
    query GetFullRoomBreakout($roomId: uuid!, $breakoutId: uuid!) {
  rooms_by_pk(id: $roomId) {
    rooms_sections(where: {breakouts: {id: {_eq: $breakoutId}}}) {
      id
      name
      image_url
      icon_image_url
      sort_order
      association_id
      room_id
      color
      type
      platform_settings
      created_at
      breakouts(where: {id: {_eq: $breakoutId}}) {
        id
        name
        type
        sort_order
        platform_settings
        room_section {
          room_id
        }
        channel_id
      }
    }
  }
}
    `;

export const useGetFullRoomBreakoutQuery = <
      TData = GetFullRoomBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetFullRoomBreakoutQueryVariables,
      options?: UseQueryOptions<GetFullRoomBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFullRoomBreakoutQuery, TError, TData>(
      ['GetFullRoomBreakout', variables],
      fetcher<GetFullRoomBreakoutQuery, GetFullRoomBreakoutQueryVariables>(client, GetFullRoomBreakoutDocument, variables, headers),
      options
    )};

useGetFullRoomBreakoutQuery.getKey = (variables: GetFullRoomBreakoutQueryVariables) => ['GetFullRoomBreakout', variables];

export const useInfiniteGetFullRoomBreakoutQuery = <
      TData = GetFullRoomBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetFullRoomBreakoutQueryVariables,
      options?: UseInfiniteQueryOptions<GetFullRoomBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetFullRoomBreakoutQuery, TError, TData>(
      ['GetFullRoomBreakout.infinite', variables],
      (metaData) => fetcher<GetFullRoomBreakoutQuery, GetFullRoomBreakoutQueryVariables>(client, GetFullRoomBreakoutDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetFullRoomBreakoutQuery.getKey = (variables: GetFullRoomBreakoutQueryVariables) => ['GetFullRoomBreakout.infinite', variables];


useGetFullRoomBreakoutQuery.fetcher = (client: GraphQLClient, variables: GetFullRoomBreakoutQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFullRoomBreakoutQuery, GetFullRoomBreakoutQueryVariables>(client, GetFullRoomBreakoutDocument, variables, headers);

export const GetFullDefaultRoomBreakoutDocument = `
    query GetFullDefaultRoomBreakout($roomId: uuid!) {
  rooms_by_pk(id: $roomId) {
    rooms_sections(limit: 1, order_by: {sort_order: asc}) {
      id
      name
      image_url
      icon_image_url
      sort_order
      association_id
      room_id
      color
      type
      platform_settings
      created_at
      breakouts(limit: 1, order_by: {sort_order: asc, created_at: asc}) {
        id
        name
        type
        sort_order
        platform_settings
        room_section {
          room_id
        }
        channel_id
      }
    }
  }
}
    `;

export const useGetFullDefaultRoomBreakoutQuery = <
      TData = GetFullDefaultRoomBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetFullDefaultRoomBreakoutQueryVariables,
      options?: UseQueryOptions<GetFullDefaultRoomBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFullDefaultRoomBreakoutQuery, TError, TData>(
      ['GetFullDefaultRoomBreakout', variables],
      fetcher<GetFullDefaultRoomBreakoutQuery, GetFullDefaultRoomBreakoutQueryVariables>(client, GetFullDefaultRoomBreakoutDocument, variables, headers),
      options
    )};

useGetFullDefaultRoomBreakoutQuery.getKey = (variables: GetFullDefaultRoomBreakoutQueryVariables) => ['GetFullDefaultRoomBreakout', variables];

export const useInfiniteGetFullDefaultRoomBreakoutQuery = <
      TData = GetFullDefaultRoomBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetFullDefaultRoomBreakoutQueryVariables,
      options?: UseInfiniteQueryOptions<GetFullDefaultRoomBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetFullDefaultRoomBreakoutQuery, TError, TData>(
      ['GetFullDefaultRoomBreakout.infinite', variables],
      (metaData) => fetcher<GetFullDefaultRoomBreakoutQuery, GetFullDefaultRoomBreakoutQueryVariables>(client, GetFullDefaultRoomBreakoutDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetFullDefaultRoomBreakoutQuery.getKey = (variables: GetFullDefaultRoomBreakoutQueryVariables) => ['GetFullDefaultRoomBreakout.infinite', variables];


useGetFullDefaultRoomBreakoutQuery.fetcher = (client: GraphQLClient, variables: GetFullDefaultRoomBreakoutQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFullDefaultRoomBreakoutQuery, GetFullDefaultRoomBreakoutQueryVariables>(client, GetFullDefaultRoomBreakoutDocument, variables, headers);

export const GetDefaultRoomBreakoutDocument = `
    query GetDefaultRoomBreakout($roomId: uuid!) {
  rooms_by_pk(id: $roomId) {
    rooms_sections(limit: 1, order_by: {sort_order: asc}) {
      breakouts(limit: 1, order_by: {sort_order: asc, created_at: asc}) {
        id
      }
    }
  }
}
    `;

export const useGetDefaultRoomBreakoutQuery = <
      TData = GetDefaultRoomBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetDefaultRoomBreakoutQueryVariables,
      options?: UseQueryOptions<GetDefaultRoomBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetDefaultRoomBreakoutQuery, TError, TData>(
      ['GetDefaultRoomBreakout', variables],
      fetcher<GetDefaultRoomBreakoutQuery, GetDefaultRoomBreakoutQueryVariables>(client, GetDefaultRoomBreakoutDocument, variables, headers),
      options
    )};

useGetDefaultRoomBreakoutQuery.getKey = (variables: GetDefaultRoomBreakoutQueryVariables) => ['GetDefaultRoomBreakout', variables];

export const useInfiniteGetDefaultRoomBreakoutQuery = <
      TData = GetDefaultRoomBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetDefaultRoomBreakoutQueryVariables,
      options?: UseInfiniteQueryOptions<GetDefaultRoomBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetDefaultRoomBreakoutQuery, TError, TData>(
      ['GetDefaultRoomBreakout.infinite', variables],
      (metaData) => fetcher<GetDefaultRoomBreakoutQuery, GetDefaultRoomBreakoutQueryVariables>(client, GetDefaultRoomBreakoutDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetDefaultRoomBreakoutQuery.getKey = (variables: GetDefaultRoomBreakoutQueryVariables) => ['GetDefaultRoomBreakout.infinite', variables];


useGetDefaultRoomBreakoutQuery.fetcher = (client: GraphQLClient, variables: GetDefaultRoomBreakoutQueryVariables, headers?: RequestInit['headers']) => fetcher<GetDefaultRoomBreakoutQuery, GetDefaultRoomBreakoutQueryVariables>(client, GetDefaultRoomBreakoutDocument, variables, headers);

export const UserUpsertRoomsSectionDocument = `
    mutation UserUpsertRoomsSection($id: uuid!, $roomId: uuid!, $name: String!, $iconImageUrl: String!, $imageUrl: String!, $sortOrder: Int! = 1, $color: String, $type: String = "public", $platformSettings: jsonb) {
  upsertRoomSection(
    id: $id
    roomId: $roomId
    name: $name
    iconImageUrl: $iconImageUrl
    imageUrl: $imageUrl
    sortOrder: $sortOrder
    color: $color
    type: $type
    platformSettings: $platformSettings
  ) {
    message
    errors {
      id
      roomId
      name
      imageUrl
      iconImageUrl
      sortOrder
      color
      type
      platformSettings
    }
  }
}
    `;

export const useUserUpsertRoomsSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserUpsertRoomsSectionMutation, TError, UserUpsertRoomsSectionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UserUpsertRoomsSectionMutation, TError, UserUpsertRoomsSectionMutationVariables, TContext>(
      ['UserUpsertRoomsSection'],
      (variables?: UserUpsertRoomsSectionMutationVariables) => fetcher<UserUpsertRoomsSectionMutation, UserUpsertRoomsSectionMutationVariables>(client, UserUpsertRoomsSectionDocument, variables, headers)(),
      options
    )};

useUserUpsertRoomsSectionMutation.getKey = () => ['UserUpsertRoomsSection'];


useUserUpsertRoomsSectionMutation.fetcher = (client: GraphQLClient, variables: UserUpsertRoomsSectionMutationVariables, headers?: RequestInit['headers']) => fetcher<UserUpsertRoomsSectionMutation, UserUpsertRoomsSectionMutationVariables>(client, UserUpsertRoomsSectionDocument, variables, headers);

export const DeleteRoomSectionDocument = `
    mutation DeleteRoomSection($id: uuid!) {
  delete_rooms_sections_by_pk(id: $id) {
    id
  }
}
    `;

export const useDeleteRoomSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteRoomSectionMutation, TError, DeleteRoomSectionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteRoomSectionMutation, TError, DeleteRoomSectionMutationVariables, TContext>(
      ['DeleteRoomSection'],
      (variables?: DeleteRoomSectionMutationVariables) => fetcher<DeleteRoomSectionMutation, DeleteRoomSectionMutationVariables>(client, DeleteRoomSectionDocument, variables, headers)(),
      options
    )};

useDeleteRoomSectionMutation.getKey = () => ['DeleteRoomSection'];


useDeleteRoomSectionMutation.fetcher = (client: GraphQLClient, variables: DeleteRoomSectionMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteRoomSectionMutation, DeleteRoomSectionMutationVariables>(client, DeleteRoomSectionDocument, variables, headers);

export const UpdateRoomsSectionsSortOrderDocument = `
    mutation UpdateRoomsSectionsSortOrder($updates: jsonb!) {
  updateRoomsSectionsSortOrders(updates: $updates) {
    success
    message
  }
}
    `;

export const useUpdateRoomsSectionsSortOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateRoomsSectionsSortOrderMutation, TError, UpdateRoomsSectionsSortOrderMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateRoomsSectionsSortOrderMutation, TError, UpdateRoomsSectionsSortOrderMutationVariables, TContext>(
      ['UpdateRoomsSectionsSortOrder'],
      (variables?: UpdateRoomsSectionsSortOrderMutationVariables) => fetcher<UpdateRoomsSectionsSortOrderMutation, UpdateRoomsSectionsSortOrderMutationVariables>(client, UpdateRoomsSectionsSortOrderDocument, variables, headers)(),
      options
    )};

useUpdateRoomsSectionsSortOrderMutation.getKey = () => ['UpdateRoomsSectionsSortOrder'];


useUpdateRoomsSectionsSortOrderMutation.fetcher = (client: GraphQLClient, variables: UpdateRoomsSectionsSortOrderMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateRoomsSectionsSortOrderMutation, UpdateRoomsSectionsSortOrderMutationVariables>(client, UpdateRoomsSectionsSortOrderDocument, variables, headers);
