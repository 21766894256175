export const getLocalStorageItem = (key: string, fallback: any) => {
  if (typeof window === 'undefined') {
    return fallback;
  }
  const storedValue = window.localStorage.getItem(key);

  return storedValue !== null ? storedValue : fallback;
};

export const setLocalStorageItem = (key: string, value: string | null | undefined) => {
  if (typeof window === 'undefined') {
    return;
  }
  if (value) {
    window.localStorage.setItem(key, value);
  } else {
    window.localStorage.removeItem(key);
  }
};
