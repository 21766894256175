import { type ReactNode, useCallback, useState } from 'react';

import { Modal, ModalOverlay } from '@/client/design-system/components/modal';

import type { Callback } from '../../../core/types/react';
import { createStrictContext } from '../../../core/utils/react';

interface IDialogContext {
  showDialog: (props: IDialog) => void;
  dismissDialog: () => void;
}

interface IDialog {
  dialog: ReactNode;
  overlay?: boolean;
  onClose?: Callback;
}

const [DialogContext, useDialog] = createStrictContext<IDialogContext>();

export const DialogProvider = ({ children }: any): JSX.Element => {
  const [dialogs, setDialogs] = useState<IDialog[]>([]);

  const showDialog = useCallback((dialog: IDialog) => {
    setDialogs((prevDialogs) => {
      return [...prevDialogs, dialog];
    });
  }, []);

  const dismissDialog = useCallback(() => {
    setDialogs((prevDialogs) => {
      return prevDialogs.slice(1);
    });
  }, []);

  const handleOpenChange = (open: boolean): void => {
    if (!open) {
      void dialogs[0]?.onClose?.();
      setDialogs((prevDialogs) => prevDialogs.slice(1));
    }
  };

  return (
    <DialogContext
      value={{
        showDialog,
        dismissDialog,
      }}
    >
      {children}

      {dialogs.length > 0 && (
        <Modal open onOpenChange={handleOpenChange}>
          {dialogs[0]?.overlay !== false && <ModalOverlay color='default' />}
          {dialogs[0]?.dialog}
        </Modal>
      )}
    </DialogContext>
  );
};

export { useDialog };
