'use client';

import { Logtail } from '@logtail/browser';

import { createStrictContext } from '../utils/react';

interface ILogContext {
  logger: Logtail;
}

const [LogContextProvider, useLogContext] = createStrictContext<ILogContext>();

export function LogProvider({ children }: any): JSX.Element {
  const logger = new Logtail(process.env.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN ?? '');

  return (
    <LogContextProvider
      value={{
        logger,
      }}
    >
      {children}
    </LogContextProvider>
  );
}

export { useLogContext };
