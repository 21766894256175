import type { DailyCall } from '@daily-co/daily-js';

import { removePresence } from './presence';

interface LeaveChannelCallProps {
  callObject: DailyCall | null;
  channelId: string;
}

export const leaveChannelCall = async ({ callObject, channelId }: LeaveChannelCallProps): Promise<void> => {
  if (!callObject) {
    return;
  }
  await Promise.all([
    removePresence({
      channelId,
    }),
    callObject.leave(),
  ]);
};
