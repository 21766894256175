import { createContext, type PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

import type { IAudioContext } from '@/client/features/audio/types/audio-context';

const Context = createContext<IAudioContext | null>(null);

export const useAudioContext = (): IAudioContext => {
  const context = useContext(Context);
  if (!context) throw new Error('"useAudioContext" must be called inside "AudioProvider"');
  return context;
};

export const AudioProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [audioContext, setAudioContext] = useState<null | AudioContext>(null);

  useEffect(() => {
    if (!audioContext) {
      setAudioContext(new AudioContext());
    }
  }, [audioContext]);

  const context = useMemo<IAudioContext>(
    () => ({
      audioContext,
    }),
    [audioContext]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
