'use client';

import React, { useEffect } from 'react';

import { useIntercom } from '@/client/features/intercom/use-intercom';
import { RoomFeedbackModal } from '@/client/features/rooms/components/feedback';
import { ChoosePlanToasts } from '@/client/features/subscriptions/choose-plan-toasts';
import { useNullableCall } from '@/providers/daily/call/call-provider';

import Meta from '../../legacy/components/meta';
import ScriptsLoader from '../core/scripts-loader';

type DefaultLayoutProps = {
  children: React.ReactNode;
  className: string;
  enableIntercom?: boolean;
  choosePlanToasts?: boolean;
};

export const DefaultLayout = ({
  children,
  className,
  enableIntercom = true,
  choosePlanToasts = true,
}: DefaultLayoutProps): JSX.Element => {
  const { enableIntercom: enableIntercomFunction } = useIntercom();
  const call = useNullableCall();

  useEffect(() => {
    if (!enableIntercom) {
      enableIntercomFunction({ hideDefaultLauncher: true });
    }
  }, [enableIntercom, enableIntercomFunction]);

  return (
    <>
      <ScriptsLoader />
      <Meta />
      <div className={`flex min-h-svh flex-col ${className} font-sans`}>
        <main className='relative flex flex-1'>
          <div className='w-full'>{children}</div>
        </main>
      </div>

      {choosePlanToasts && <ChoosePlanToasts />}
      {call && <RoomFeedbackModal />}
    </>
  );
};
