'use client';

import { useEffect, useRef } from 'react';

function usePreviousNonNull<T>(value: T | undefined): T | undefined {
  const ref = useRef<T | undefined>(value);

  useEffect(() => {
    if (value !== null && value !== undefined) {
      ref.current = value;
    }
  }, [value]);

  return ref.current;
}

export function usePrevious<T>(value: T | undefined): T | undefined {
  const ref = useRef<T | undefined>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default usePreviousNonNull;
