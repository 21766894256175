import hasura from './services/hasura';

export const insertPlatformInvite = async (code: string, invitee: string, type: string) => {
  const query = `
    mutation InsertUserInvite($code: String!, $invitee: String!, $type: String!) {
      insert_user_invites_one(object: {code: $code, invitee: $invitee, type: $type, kind: "platform"}) {
        id
      }
    }
  `;

  const { data } = await hasura.userRequest(query, { code, invitee, type });

  return data?.insert_user_invites_one ?? { message: 'Inviting the same invitee again is not allowed!' };
};

export const getPlatformInvites = async () => {
  const query = `
    query GetUserInvites {
      user_invites(where: {kind: {_eq: "platform"}},order_by: {created_at: desc}) {
        invitee
        status
        created_at
      }
    }
  `;

  const { data } = await hasura.userRequest(query);

  return data.user_invites;
};

export const login = async ({
  username,
  email,
  phone,
  password,
  turnstileToken,
}: {
  username?: string | null;
  email?: string | null;
  phone?: string | null;
  password?: string;
  turnstileToken?: string;
}) => {
  if ((!username && !email && !phone) || !password) {
    return {
      error: {
        type: 'credentials',
      },
    };
  }
  const res = await fetch('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      email,
      phone,
      password,
      turnstileToken,
    }),
  });

  const data = await res.json();

  if (res.status === 403) {
    return {
      error: {
        type: 'disabled',
        message: data.message,
      },
    };
  }

  return data;
};

export const getCurrentUser = async () => {
  const data = await fetch('/api/user').then((r) => r.json());
  return data?.user;
};

export const getUserByEmail = async (email: string) => {
  const query = `
    query GetUserByEmail($email: String!) {
      users(where: { email: { _eq: $email } }) {
        id
        photo
        email
      }
    }
  `;

  const variables = {
    email,
  };

  const { data } = await hasura.userRequest(query, variables);

  return data.users.length ? data.users[0] : null;
};

export const getUserByPhone = async (phone: string) => {
  const query = `
    query GetUserByPhone($phone: String!) {
      users(where: { phone: { _eq: $phone } }) {
        id
        photo
        phone
      }
    }
  `;

  const variables = {
    phone,
  };

  const { data } = await hasura.userRequest(query, variables);

  return data.users.length ? data.users[0] : null;
};

export const updateBirthday = async ({ userId, birthday }: { userId?: string; birthday?: Date } = {}) => {
  const query = `
    mutation UpdateBirthday($userId: String!, $birthday: date!) {
      update_users_by_pk(pk_columns: { id: $userId }, _set: { birthday: $birthday }) {
        birthday
      }
    }
  `;

  const variables = {
    userId,
    birthday,
  };

  const { data } = await hasura.userRequest(query, variables);

  return data.update_users_by_pk?.birthday;
};

export const updateUserAddress = async ({ userId, address }: { userId?: string; address?: string } = {}) => {
  const query = `
    mutation UpdateShippingAddress($user_id: String!, $address: String!) {
      update_users_by_pk(pk_columns: { id: $user_id }, _set: { address: $address }) {
        id
        address
      }
    }
  `;

  const variables = {
    user_id: userId,
    address,
  };

  const { data } = await hasura.userRequest(query, variables);

  return data.update_users_by_pk?.address;
};

export const purchaseItem = async ({ itemId, notes }: { itemId?: string; notes?: string } = {}) => {
  const query = `
    mutation PurchaseItem($item_id: String!, $notes: String) {
      purchaseItem(item_id: $item_id, notes: $notes) {
        order_id
      }
    }
  `;

  const variables = {
    item_id: itemId,
    notes,
  };

  const { data } = await hasura.userRequest(query, variables);

  return data.purchaseItem?.order_id;
};

export const findTeamForUser = async (urlName: string) => {
  const query = `
    query FindTeamForUser($urlName: String!) {
      channels(where: {competition: {url_name: {_eq: $urlName}}, channels_users: {user: {current: {_eq: true}}}}) {
        id
        name
        project {
          id
          review_text
          status
          place
          money
          xp
          prize
          special_prizes
        }
        channels_users {
          user {
            id
            photo
            username
          }
          role
          meta
        }
        channels_users_aggregate {
          aggregate {
            count
          }
        }
      }
    }`;

  const { data } = await hasura.userRequest(query, { urlName });

  return data.channels.length ? data.channels[0] : null;
};

export const insertUserNotifications = async (users_notifications: any) => {
  const query = `
    mutation InsertUsersNotifications($users_notifications: [users_notifications_insert_input!]!) {
      insert_users_notifications(objects: $users_notifications, on_conflict: {constraint: users_notifications_pkey, update_columns: []}) {
        affected_rows
      }
    }
  `;

  const { data } = await hasura.userRequest(query, { users_notifications });

  return data.insert_users_notifications;
};
