import { forwardRef, type SVGAttributes } from 'react';

import { icons, type IconType } from './icons';

interface Props extends SVGAttributes<SVGSVGElement> {
  icon: IconType;
  color?: string;
  size?: number;
}

export const Icon = forwardRef<SVGSVGElement, Props>(({ icon, size, color, ...props }, forwardedRef) => {
  const { component: IconComponent, dimensions } = icons[icon];
  const [width, height] = dimensions;
  const sizeWithFallback = size ?? height;
  const aspectRatio = width / height;
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <IconComponent
      {...props}
      style={{ color }}
      ref={forwardedRef}
      width={sizeWithFallback}
      height={sizeWithFallback / aspectRatio}
      viewBox={viewBox}
    />
  );
});
