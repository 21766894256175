'use client';

import _ from 'lodash';
import { useReducer } from 'react';

import { useGraphQLSubscription } from '@/client/core/hooks/use-graphql-subscription';
import type {
  GetUserPresenceQuery,
  GetUserPresenceQueryVariables,
} from '@/client/features/user/operations/generated/get-user-presence.user';
import { GetUserPresenceDocument } from '@/client/features/user/operations/generated/get-user-presence.user';
import type { UsersPresenceMeta } from '@/shared/graphql/scalars';

type PresenceState = UsersPresenceMeta & {
  loading: boolean;
  error?: unknown;
};

export const useUserPresence = (id?: string) => {
  const [presence, setPresence] = useReducer(
    (previous: PresenceState, next: PresenceState) => {
      return _.isEqual(previous, next) ? previous : next;
    },
    { loading: true }
  );

  useGraphQLSubscription<GetUserPresenceQuery, GetUserPresenceQueryVariables>({
    ...(id
      ? {
          enabled: true,
          variables: {
            user_id: id,
          },
        }
      : {
          enabled: false,
        }),
    query: GetUserPresenceDocument,
    onNext: (data) => {
      const updatedPresence = data?.users_by_pk?.presence;
      setPresence({ loading: false, ...updatedPresence });
    },
    onError: (error) => {
      setPresence({
        loading: false,
        error,
      });
    },
  });

  return presence;
};
