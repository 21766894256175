import { type Channel_Type, type Room_Kind } from '@/generated/graphql/global-types.anonymous';

export enum UserActionType {
  Navigate = 'navigate',
}

export enum UserActionPredicateType {
  InCall = 'in_call',
  InCallKind = 'in_call_kind',
  Not = 'not',
  Or = 'or',
  Path = 'path',
}

export type BaseUserActionPredicate<T extends UserActionPredicateType> = {
  type: T;
};

export type InCallUserActionPredicate = BaseUserActionPredicate<UserActionPredicateType.InCall> & {
  channelId?: string;
  channelType?: Channel_Type;
};

export type InCallKindActionPredicate = BaseUserActionPredicate<UserActionPredicateType.InCallKind> & {
  roomKind?: Room_Kind;
};

export type NotUserActionPredicate = BaseUserActionPredicate<UserActionPredicateType.Not> & {
  predicate: UserActionPredicate;
};

export type OrUserActionPredicate = BaseUserActionPredicate<UserActionPredicateType.Or> & {
  predicates: UserActionPredicate[];
};

export type PathUserActionPredicate = BaseUserActionPredicate<UserActionPredicateType.Path> & {
  pathRegex: string;
};

export type UserActionPredicate =
  | InCallUserActionPredicate
  | InCallKindActionPredicate
  | NotUserActionPredicate
  | OrUserActionPredicate
  | PathUserActionPredicate;

type BaseUserAction<T extends UserActionType> = {
  type: T;
  predicates?: UserActionPredicate[]; // Only perform the action if the app meets the specified predicate conditions.

  /**
   * Default: false. By default, if a predicate does not match, the client will still mark the action in the DB as
   * executed i.e. the action is ignored. When true, the action will not be marked as executed, and predicate will be
   * checked again in the future. It's worth noting, the predicate won't be checked again until either more actions come
   * in or the entire web app is freshly loaded again.
   */
  executionRequired?: boolean;
};

export type RouteUserAction = BaseUserAction<UserActionType.Navigate> & {
  url: string;
  replace?: boolean;
  force?: boolean;
};

export type UserAction = RouteUserAction;
