import { useEffect, useRef } from 'react';

type CallbackReturnType = ReturnType<Parameters<typeof useEffect>[0]>;

export function useDependencyTrackingEffect<const D extends readonly unknown[]>(
  callback: (previous: undefined | D) => CallbackReturnType,
  deps: D
) {
  const persistedDeps = useRef<D>();
  useEffect(() => {
    const previousDeps = persistedDeps.current;
    persistedDeps.current = deps;
    return callback(previousDeps);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We just want to pass through the deps, callback should not be tracked.
  }, deps);
}
