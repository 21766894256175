import { useIsomorphicLayoutEffect } from 'framer-motion';
import { useRef } from 'react';

export function useStableCallback<T extends (...args: any) => any>(this: any, callbackFn: T): T {
  const callbackRef = useRef<T>(callbackFn);

  useIsomorphicLayoutEffect(() => {
    callbackRef.current = callbackFn;
  });

  return useRef(((...args: Parameters<T>) => callbackRef.current.apply(this, args)) as T).current;
}
