import React, { type FocusEventHandler, forwardRef, type InputHTMLAttributes, useCallback, useState } from 'react';

import { FloatingLabel } from '@/client/design-system/components/fields/components/floating-label';
import { InputContainer } from '@/client/design-system/components/fields/components/input-container';

import { cn } from '../../utils/tailwind';

export interface InputProps extends React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  checkmark?: boolean;
  error?: null | string;
  hideError?: boolean;
  label?: string;
  thin?: boolean;
  tooltip?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const {
    checkmark,
    className,
    disabled = false,
    error,
    hideError = false,
    id,
    label,
    onBlur,
    onFocus,
    thin = false,
    tooltip,
    value,
    ...inputProps
  } = props;

  const [focus, setFocus] = useState(false);

  const innerOnFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setFocus(true);
      onFocus?.(event);
    },
    [onFocus]
  );

  const innerOnBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setFocus(false);
      onBlur?.(event);
    },
    [onBlur]
  );

  const showValue = focus || !!value;

  return (
    <InputContainer
      id={id}
      checkmark={checkmark}
      tooltip={tooltip}
      hideError={hideError}
      error={error}
      className={className}
    >
      {!thin && label && <FloatingLabel label={label} focus={focus} hasError={!!error} small={showValue} />}
      <input
        {...inputProps}
        className={cn(
          'w-full rounded-sm border bg-dark-grey-1 px-3.6 text-paragraph3 font-medium outline-none transition-colors duration-100 placeholder:text-transparent disabled:opacity-50',
          showValue && !thin ? 'pt-4' : 'pt-0',
          !error && !focus && !disabled && 'group-hover:border-disabled-1',
          error ? 'border-accent-red/40' : 'border-disabled-2 focus:border-accent/40',
          thin ? 'h-10 placeholder:text-light-grey-4' : 'h-14'
        )}
        disabled={disabled}
        onBlur={innerOnBlur}
        onFocus={innerOnFocus}
        placeholder={thin ? label : ''}
        ref={ref}
        style={{ caretColor: error ? '#FF0000' : '#00FF47' }}
      />
    </InputContainer>
  );
});
