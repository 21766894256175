import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetChannelUsersQueryVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
}>;


export type GetChannelUsersQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly channels_users: ReadonlyArray<(
    { readonly __typename?: 'channels_users' }
    & Pick<Types.Channels_Users, 'user_id' | 'status' | 'hand_raised' | 'force_muted' | 'connection'>
    & { readonly profile: (
      { readonly __typename?: 'users' }
      & Pick<Types.Users, 'photo' | 'username' | 'first_name' | 'role'>
    ) }
  )> }
);

export type GetRoomsSectionChannelUsersQueryVariables = Types.Exact<{
  channelIds: ReadonlyArray<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
}>;


export type GetRoomsSectionChannelUsersQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly channels_users: ReadonlyArray<(
    { readonly __typename?: 'channels_users' }
    & Pick<Types.Channels_Users, 'id' | 'role' | 'hand_raised' | 'hand_raised_updated_at' | 'connection' | 'volume' | 'status_changed_at' | 'force_muted'>
    & { readonly user: (
      { readonly __typename?: 'users' }
      & Pick<Types.Users, 'id' | 'username' | 'photo' | 'role'>
    ), readonly channel: (
      { readonly __typename?: 'channels' }
      & Pick<Types.Channels, 'id'>
      & { readonly breakout?: Types.Maybe<(
        { readonly __typename?: 'breakouts' }
        & Pick<Types.Breakouts, 'id'>
      )> }
    ) }
  )> }
);



export const GetChannelUsersDocument = `
    query GetChannelUsers($channelId: uuid!) {
  channels_users(where: {status: {_eq: active}, channel_id: {_eq: $channelId}}) {
    user_id
    status
    hand_raised
    force_muted
    connection
    profile: user {
      photo
      username
      first_name
      role
    }
  }
}
    `;

export const useGetChannelUsersQuery = <
      TData = GetChannelUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetChannelUsersQueryVariables,
      options?: UseQueryOptions<GetChannelUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetChannelUsersQuery, TError, TData>(
      ['GetChannelUsers', variables],
      fetcher<GetChannelUsersQuery, GetChannelUsersQueryVariables>(client, GetChannelUsersDocument, variables, headers),
      options
    )};

useGetChannelUsersQuery.getKey = (variables: GetChannelUsersQueryVariables) => ['GetChannelUsers', variables];

export const useInfiniteGetChannelUsersQuery = <
      TData = GetChannelUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetChannelUsersQueryVariables,
      options?: UseInfiniteQueryOptions<GetChannelUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetChannelUsersQuery, TError, TData>(
      ['GetChannelUsers.infinite', variables],
      (metaData) => fetcher<GetChannelUsersQuery, GetChannelUsersQueryVariables>(client, GetChannelUsersDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetChannelUsersQuery.getKey = (variables: GetChannelUsersQueryVariables) => ['GetChannelUsers.infinite', variables];


useGetChannelUsersQuery.fetcher = (client: GraphQLClient, variables: GetChannelUsersQueryVariables, headers?: RequestInit['headers']) => fetcher<GetChannelUsersQuery, GetChannelUsersQueryVariables>(client, GetChannelUsersDocument, variables, headers);

export const GetRoomsSectionChannelUsersDocument = `
    query GetRoomsSectionChannelUsers($channelIds: [uuid!]!) {
  channels_users(where: {status: {_eq: active}, channel_id: {_in: $channelIds}}) {
    id
    role
    hand_raised
    hand_raised_updated_at
    connection
    volume
    status_changed_at
    force_muted
    user {
      id
      username
      photo
      role
    }
    channel {
      id
      breakout {
        id
      }
    }
  }
}
    `;

export const useGetRoomsSectionChannelUsersQuery = <
      TData = GetRoomsSectionChannelUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomsSectionChannelUsersQueryVariables,
      options?: UseQueryOptions<GetRoomsSectionChannelUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRoomsSectionChannelUsersQuery, TError, TData>(
      ['GetRoomsSectionChannelUsers', variables],
      fetcher<GetRoomsSectionChannelUsersQuery, GetRoomsSectionChannelUsersQueryVariables>(client, GetRoomsSectionChannelUsersDocument, variables, headers),
      options
    )};

useGetRoomsSectionChannelUsersQuery.getKey = (variables: GetRoomsSectionChannelUsersQueryVariables) => ['GetRoomsSectionChannelUsers', variables];

export const useInfiniteGetRoomsSectionChannelUsersQuery = <
      TData = GetRoomsSectionChannelUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomsSectionChannelUsersQueryVariables,
      options?: UseInfiniteQueryOptions<GetRoomsSectionChannelUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetRoomsSectionChannelUsersQuery, TError, TData>(
      ['GetRoomsSectionChannelUsers.infinite', variables],
      (metaData) => fetcher<GetRoomsSectionChannelUsersQuery, GetRoomsSectionChannelUsersQueryVariables>(client, GetRoomsSectionChannelUsersDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetRoomsSectionChannelUsersQuery.getKey = (variables: GetRoomsSectionChannelUsersQueryVariables) => ['GetRoomsSectionChannelUsers.infinite', variables];


useGetRoomsSectionChannelUsersQuery.fetcher = (client: GraphQLClient, variables: GetRoomsSectionChannelUsersQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRoomsSectionChannelUsersQuery, GetRoomsSectionChannelUsersQueryVariables>(client, GetRoomsSectionChannelUsersDocument, variables, headers);
