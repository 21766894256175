import 'emoji-mart/css/emoji-mart.css';

import {
  type DetailedHTMLProps,
  type FocusEventHandler,
  type KeyboardEvent,
  type TextareaHTMLAttributes,
  useCallback,
  useState,
} from 'react';

interface Props extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string;
  error?: any;
  disabled?: boolean;
  heightOverride?: string;
  paddingOverride?: string;
  fontOverride?: string;
  displayErrorMessage?: boolean;
  className?: string;
  id?: string;
}

export const Textarea = (props: Props) => {
  const {
    className,
    disabled = false,
    displayErrorMessage = true,
    error,
    fontOverride,
    heightOverride,
    id,
    label,
    onBlur,
    onFocus,
    paddingOverride,
    ...textAreaProps
  } = props;

  const [focus, setFocus] = useState(false);

  const innerOnFocus: FocusEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      setFocus(true);
      onFocus?.(event);
    },
    [onFocus]
  );

  const innerOnBlur: FocusEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      setFocus(false);
      onBlur?.(event);
    },
    [onBlur]
  );

  const expandTextArea = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    // Expand the text area as the user types
    const textarea = e.target as HTMLTextAreaElement;
    textarea.style.height = '';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 300)}px`;
  };

  const getLabelCSS = (): string => {
    if (focus && !error) return 'text-accent';
    else if (error) return 'text-accent-red';
    else return 'text-light-grey-2';
  };

  return (
    <div className={className} id={id}>
      <div
        className={`relative flex flex-col rounded-sm border bg-dark-grey-1 outline-none transition-all duration-100 ${
          error ? 'border-accent-red' : `${focus ? 'border-white' : 'border-disabled-2'}`
        } ${!error && !focus && !disabled && 'hover:border-light-grey-2'}`}
      >
        {label && (
          <label
            className={`mt-2 cursor-text pl-4 text-caption1 transition-all duration-150 ${getLabelCSS()}`}
            onClick={(e) => {
              const target = e.target as HTMLLabelElement;
              (target.nextSibling as HTMLTextAreaElement).focus();
            }}
          >
            {label}
          </label>
        )}

        <textarea
          {...textAreaProps}
          className={`w-full grow resize-none overflow-hidden border-none bg-transparent ${
            paddingOverride ?? 'px-4 pb-2.5'
          }
            font-semibold text-white shadow-transparent outline-none placeholder:text-disabled-1 ${
              fontOverride ?? 'text-headline4'
            } ${heightOverride ?? 'h-40'} ${!label && (paddingOverride ? '' : 'pt-2.5')} `}
          disabled={disabled}
          onBlur={innerOnBlur}
          onFocus={innerOnFocus}
          onInput={expandTextArea}
        />
      </div>

      {displayErrorMessage && error && (
        <div className='mb-1 mt-1.5 pl-4 text-left text-caption1 text-accent-red'>{error ?? '\uFEFF'}</div>
      )}
    </div>
  );
};
