'use client';

import { useCallback, useEffect } from 'react';

import { pageView } from '@/client/features/google-tag-manager/api';
import { useRouter } from '@/hooks/use-compatible-router';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const authId = process.env.NEXT_PUBLIC_GTM_AUTH;
const preview = process.env.NEXT_PUBLIC_GTM_PREVIEW;
const trackingId = process.env.NEXT_PUBLIC_GTM_TRACKING_ID;

export const GoogleTagManagerHead = (): null | JSX.Element => {
  if (!authId || !preview || !trackingId) {
    console.warn('GTM not enabled');
    return null;
  }

  // This is certainly not very React-like. However, this is how Google Tag Manager expects to be installed. See:
  // https://developers.google.com/tag-platform/gtagjs/install
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: [
          `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':`,
          `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],`,
          `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=`,
          `'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${authId}&gtm_preview=${preview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);`,
          `})(window,document,'script','dataLayer','${trackingId}');`,
          'window.dataLayer = window.dataLayer || [];',
          'window.gtag = function() { window.dataLayer.push(arguments); };',
        ].join('\n'),
      }}
    />
  );
};

export const GoogleTagManagerBody = (): null | JSX.Element => {
  if (!authId || !preview || !trackingId) {
    return null;
  }

  return (
    <noscript>
      <iframe
        aria-hidden
        src={`https://www.googletagmanager.com/ns.html?id=${trackingId}&gtm_auth=${authId}&gtm_preview=${preview}&gtm_cookies_win=x`}
        height='0'
        width='0'
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};

export const GoogleTagManagerRouteObserver = (): null => {
  const router = useRouter();

  const onRouteChange = useCallback((route: string) => {
    if (!trackingId) {
      return;
    }
    const url = window.location.origin + route;

    pageView({ trackingId, url });
  }, []);

  useEffect(() => {
    if (!router.pathname) {
      return;
    }
    onRouteChange(router.pathname);
  }, [onRouteChange, router.pathname]);

  useEffect(() => {
    if (!trackingId) {
      return;
    }

    // Initial page view
    pageView({
      trackingId,
      url: window.location.toString(),
    });
  }, [onRouteChange]);

  return null;
};
