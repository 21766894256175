import type { CSS } from '@stitches/react';

export const variables = (vars: Record<string, string | number | undefined>): CSS => {
  return Object.fromEntries(
    Object.entries(vars)
      .filter(filterOutUndefinedValues)
      .map(([key, value]) => [prefixCssVariableKey(key), escapePixelValues(value)])
  );
};

const filterOutUndefinedValues = (entry: [string, string | number | undefined]): entry is [string, string | number] => {
  const [, value] = entry;
  return value !== undefined;
};

const escapePixelValues = (value: number | string): string => (typeof value === 'number' ? `${value}px` : value);

const prefixCssVariableKey = (key: string): string => `$$${key}`;
