import dynamic from 'next/dynamic';

import { createIconDictionary } from './utils';

export type IconType = keyof typeof icons;

export const icons = createIconDictionary({
  'ender-logo': {
    component: dynamic(async () => await import('../../icons/ender-logo.svg')),
    dimensions: [30, 34],
    fillMethod: 'fill',
  },
  trophy: {
    component: dynamic(async () => await import('../../icons/trophy.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  box: {
    component: dynamic(async () => await import('../../icons/box.svg')),
    dimensions: [20, 20],
    fillMethod: 'fill',
  },
  maximize: {
    component: dynamic(async () => await import('../../icons/maximize.svg')),
    dimensions: [24, 24],
    fillMethod: 'fill',
  },
  discord: {
    component: dynamic(async () => await import('../../icons/discord.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  minecraft: {
    component: dynamic(async () => await import('../../icons/minecraft.svg')),
    dimensions: [24, 24],
    fillMethod: 'fill',
  },
  check: {
    component: dynamic(async () => await import('../../icons/check.svg')),
    dimensions: [14, 14],
    fillMethod: 'stroke',
  },
  checkmark: {
    component: dynamic(async () => await import('../../icons/checkmark.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'check-small': {
    component: dynamic(async () => await import('../../icons/check-small.svg')),
    dimensions: [12, 12],
    fillMethod: 'stroke',
  },
  'thick-check': {
    component: dynamic(async () => await import('../../icons/thick-check.svg')),
    dimensions: [8, 8],
    fillMethod: 'stroke',
  },
  home: {
    component: dynamic(async () => await import('../../icons/home.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  activity: {
    component: dynamic(async () => await import('../../icons/activity.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  book: {
    component: dynamic(async () => await import('../../icons/book.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  bell: {
    component: dynamic(async () => await import('../../icons/bell.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'user-plus': {
    component: dynamic(async () => await import('../../icons/user-plus.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'user-minus': {
    component: dynamic(async () => await import('../../icons/user-minus.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'users-plus': {
    component: dynamic(async () => await import('../../icons/users-plus.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  speaker: {
    component: dynamic(async () => await import('../../icons/speaker.svg')),
    dimensions: [480, 512],
    fillMethod: 'stroke',
  },
  speaker2: {
    component: dynamic(async () => await import('../../icons/speaker2.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'chevron-down': {
    component: dynamic(async () => await import('../../icons/chevron-down.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'chevron-up': {
    component: dynamic(async () => await import('../../icons/chevron-up.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  share: {
    component: dynamic(async () => await import('../../icons/share.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  swipe: {
    component: dynamic(async () => await import('../../icons/swipe.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  mouse: {
    component: dynamic(async () => await import('../../icons/mouse.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  grid: {
    component: dynamic(async () => await import('../../icons/grid.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'chevron-left': {
    component: dynamic(async () => await import('../../icons/chevron-left.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'chevron-right': {
    component: dynamic(async () => await import('../../icons/chevron-right.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  heart: {
    component: dynamic(async () => await import('../../icons/heart.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'heart-filled': {
    component: dynamic(async () => await import('../../icons/heart-filled.svg')),
    dimensions: [24, 24],
    fillMethod: 'both',
  },
  smile: {
    component: dynamic(async () => await import('../../icons/smile.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  copy: {
    component: dynamic(async () => await import('../../icons/copy.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'open-link': {
    component: dynamic(async () => await import('../../icons/open-link.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  link: {
    component: dynamic(async () => await import('../../icons/link.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  report: {
    component: dynamic(async () => await import('../../icons/report.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  trash: {
    component: dynamic(async () => await import('../../icons/trash.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  elipsis: {
    component: dynamic(async () => await import('../../icons/ellipsis.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  timer: {
    component: dynamic(async () => await import('../../icons/timer.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  gift: {
    component: dynamic(async () => await import('../../icons/gift.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'unordered-list': {
    component: dynamic(async () => await import('../../icons/unordered-list.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  users: {
    component: dynamic(async () => await import('../../icons/users.svg')),
    dimensions: [640, 512],
    fillMethod: 'fill',
  },
  star: {
    component: dynamic(async () => await import('../../icons/star.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'plus-circle': {
    component: dynamic(async () => await import('../../icons/plus-circle.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'folder-plus': {
    component: dynamic(async () => await import('../../icons/folder-plus.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'photo-plus': {
    component: dynamic(async () => await import('../../icons/photo-plus.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  information: {
    component: dynamic(async () => await import('../../icons/information.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  plus: {
    component: dynamic(async () => await import('../../icons/plus.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  minus: {
    component: dynamic(async () => await import('../../icons/minus.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  switch: {
    component: dynamic(async () => await import('../../icons/switch.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  robot: {
    component: dynamic(async () => await import('../../icons/robot.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  settings: {
    component: dynamic(async () => await import('../../icons/settings.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  close: {
    component: dynamic(async () => await import('../../icons/close.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  rocket: {
    component: dynamic(async () => await import('../../icons/rocket.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  linked: {
    component: dynamic(async () => await import('../../icons/linked.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  question: {
    component: dynamic(async () => await import('../../icons/question.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'double-chat-bubble': {
    component: dynamic(async () => await import('../../icons/double-chat-bubble.svg')),
    dimensions: [24, 24],
    fillMethod: 'both',
  },
  eye: {
    component: dynamic(async () => await import('../../icons/eye.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'user-circle': {
    component: dynamic(async () => await import('../../icons/user-circle.svg')),
    dimensions: [24, 24],
    fillMethod: 'fill',
  },
  upload: {
    component: dynamic(async () => await import('../../icons/upload.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'align-arrow-up': {
    component: dynamic(async () => await import('../../icons/align-arrow-up.svg')),
    dimensions: [20, 20],
    fillMethod: 'stroke',
  },
  'align-arrow-down': {
    component: dynamic(async () => await import('../../icons/align-arrow-down.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'log-in': {
    component: dynamic(async () => await import('../../icons/log-in.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'log-out': {
    component: dynamic(async () => await import('../../icons/log-out.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  'circle-check': {
    component: dynamic(async () => await import('../../icons/check-circle.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  'circle-cross': {
    component: dynamic(async () => await import('../../icons/circle-cross.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  'user-check': {
    component: dynamic(async () => await import('../../icons/user-check.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  spinner: {
    component: dynamic(async () => await import('../../icons/spinner.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  sun: {
    component: dynamic(async () => await import('../../icons/sun.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  moon: {
    component: dynamic(async () => await import('../../icons/moon.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  'circle-half-filled': {
    component: dynamic(async () => await import('../../icons/circle-outline-50-percent.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  'circle-third-filled': {
    component: dynamic(async () => await import('../../icons/circle-outline-33-percent.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  party: {
    component: dynamic(async () => await import('../../icons/party.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  hashtag: {
    component: dynamic(async () => await import('../../icons/hashtag.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  headphones: {
    component: dynamic(async () => await import('../../icons/headphones.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  rows: {
    component: dynamic(async () => await import('../../icons/rows.svg')),
    dimensions: [24, 24],
    fillMethod: 'both',
  },
  sign: {
    component: dynamic(async () => await import('../../icons/sign.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'shield-tick': {
    component: dynamic(async () => await import('../../icons/shield-tick.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  forbid: {
    component: dynamic(async () => await import('../../icons/forbid.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  lock: {
    component: dynamic(async () => await import('../../icons/lock.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
  'layout-left': {
    component: dynamic(async () => await import('../../icons/layout-left.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'ender-cash': {
    component: dynamic(async () => await import('../../icons/ender-cash.svg')),
    dimensions: [34, 34],
    fillMethod: 'stroke',
  },
  'shopping-bag': {
    component: dynamic(async () => await import('../../icons/shopping-bag.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'help-circle': {
    component: dynamic(async () => await import('../../icons/help-circle.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  sparkles: {
    component: dynamic(async () => await import('../../icons/sparkles.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  help: {
    component: dynamic(async () => await import('../../icons/help.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  send: {
    component: dynamic(async () => await import('../../icons/send.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'arrow-right': {
    component: dynamic(async () => await import('../../icons/arrow-right.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  search: {
    component: dynamic(async () => await import('../../icons/search.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'speech-bubble-plus': {
    component: dynamic(async () => await import('../../icons/speech-bubble-plus.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  clock: {
    component: dynamic(async () => await import('../../icons/clock.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'search-globe': {
    component: dynamic(async () => await import('../../icons/search-globe.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  'door-enter': {
    component: dynamic(async () => await import('../../icons/door-enter.svg')),
    dimensions: [24, 24],
    fillMethod: 'stroke',
  },
  xbox: {
    component: dynamic(async () => await import('../../icons/xbox.svg')),
    dimensions: [512, 512],
    fillMethod: 'fill',
  },
  microphone: {
    component: dynamic(async () => await import('../../icons/microphone.svg')),
    dimensions: [16, 16],
    fillMethod: 'stroke',
  },
});
