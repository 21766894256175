/**
 * What is this monstrosity? Why isn't it a regular GraphQL enum? What's with the inconsistent values?
 * Great questions! I'm glad you asked.
 *
 * So. We previously had two different enum type definitions, one in or GraphQL schema extensions directory and one
 * within our app code. Why? No idea. However, there was *zero* overlap between the values. Better still, there were a
 * plethora of hard-coded strings being used and possibly even additional values coming from outside this codebase.
 * Anyway, what follows is a list that was (at the time of writing) exported from our production database. Obviously we
 * don't want to be stylistically discriminatory, so we've been sure to include representation from a variety of styles:
 * camel case, snake case, colon separated. Also, birthdays are a special time of year, so doing something special and
 * using a hyphen must have felt right.
 *
 * What's really great is a significant portion of these *can't* be represented as GraphQL enum values since they're
 * not valid GraphQL names. Thus, here we are.
 *
 * TODO: (For someone feeling braver than I.) A database migration that cleans up this mess.
 */
export enum NotificationType {
  Custom = 'custom',
  FeedbackInsert = 'feedback:insert',
  FollowInsert = 'followInsert',
  FriendRequest = 'friend_request',
  FriendRequestAccepted = 'friend_request_accepted',
  InviteSignupAward = 'inviteSignupAward',
  ItemsStatusApproved = 'items:status:approved',
  ItemSuggestionsInsert = 'item_suggestions:insert',
  OrderInsert = 'orderInsert',
  RoomUnlocksSoon = 'room:unlocks_soon',
  ThemeActive = 'themeActive',
  /** @deprecated Replaced by Theme */
  ThemeAnnouncement = 'themeAnnouncement',
  UserBirthday = 'user-birthday',
  UserInsert = 'userInsert',
  UserInviteAward = 'userInviteAward',
  UserInviteInsert = 'userInviteInsert',
  UserInvitesPlatformInsert = 'user_invites:platform:insert',
  UserPasswordReset = 'userPasswordReset',
  UsersBadgesInsert = 'users_badges_insert',
  WaitlistInsert = 'waitlistInsert',
}
