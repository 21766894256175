import {
  type DetailedHTMLProps,
  type FocusEventHandler,
  forwardRef,
  type TextareaHTMLAttributes,
  useCallback,
  useState,
} from 'react';

import { FloatingLabel } from '@/client/design-system/components/fields/components/floating-label';
import { InputContainer } from '@/client/design-system/components/fields/components/input-container';
import { cn } from '@/client/design-system/utils/tailwind';

export interface TextareaInputProps
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  checkmark?: boolean;
  error?: null | string;
  hideError?: boolean;
  label?: string;
  multiline?: boolean;
  textareaClassname?: string;
  thin?: boolean;
  tooltip?: string;
}

export const TextareaInput = forwardRef<HTMLTextAreaElement, TextareaInputProps>(function TextareaInput(props, ref) {
  const {
    checkmark,
    className,
    disabled = false,
    error,
    hideError = false,
    id,
    label,
    multiline = true,
    onBlur,
    onFocus,
    textareaClassname,
    thin = false,
    tooltip,
    value,
    ...textAreaProps
  } = props;

  const [focus, setFocus] = useState(false);

  const innerOnFocus: FocusEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      setFocus(true);
      onFocus?.(event);
    },
    [onFocus]
  );

  const innerOnBlur: FocusEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      setFocus(false);
      onBlur?.(event);
    },
    [onBlur]
  );

  const showValue = focus || !!value;

  const expandTextArea = (target: HTMLTextAreaElement): void => {
    target.style.height = '';
    target.style.height = `${Math.min(target.scrollHeight, 256)}px`;
  };

  return (
    <InputContainer
      id={id}
      checkmark={checkmark}
      tooltip={tooltip}
      hideError={hideError}
      error={error}
      className={className}
    >
      {!thin && label && <FloatingLabel label={label} focus={focus} hasError={!!error} small={showValue} />}
      <textarea
        {...textAreaProps}
        className={cn(
          'max-h-36 w-full resize-none rounded-sm border bg-dark-grey-1 px-3.6 text-paragraph3 font-medium outline-none transition-colors duration-100 scrollbar-hide placeholder:text-transparent disabled:opacity-50',
          showValue && !thin ? 'pt-6' : 'py-2',
          !error && !focus && !disabled && 'group-hover:border-disabled-1',
          error ? 'border-accent-red/40' : 'border-disabled-2 focus:border-accent/40',
          thin ? 'h-10 placeholder:text-light-grey-4' : 'h-36'
        )}
        disabled={disabled}
        onBlur={innerOnBlur}
        onFocus={innerOnFocus}
        onInput={(event) => {
          expandTextArea(event.currentTarget);
        }}
        onKeyDown={(event) => {
          if (!multiline && event.key === 'Enter') {
            event.preventDefault();

            if (!event.repeat && !event.shiftKey) {
              const target = event.currentTarget;
              target.form?.requestSubmit();
              target.value = '';
              target.style.height = '';
              target.style.height = `${Math.min(target.scrollHeight, 256)}px`;
            }
          }
        }}
        placeholder={thin ? label : ''}
        ref={ref}
      />
    </InputContainer>
  );
});
