import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GenerateCallAccessTokenMutationVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
  channelId: Types.Scalars['uuid']['input'];
  startMuted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GenerateCallAccessTokenMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly callAccessToken?: Types.Maybe<(
    { readonly __typename?: 'generateCallAccessTokenOutput' }
    & Pick<Types.GenerateCallAccessTokenOutput, 'token' | 'exp'>
  )> }
);



export const GenerateCallAccessTokenDocument = `
    mutation GenerateCallAccessToken($roomId: uuid!, $channelId: uuid!, $startMuted: Boolean) {
  callAccessToken: generateCallAccessToken(
    roomId: $roomId
    channelId: $channelId
    startAudioOff: $startMuted
  ) {
    token
    exp
  }
}
    `;

export const useGenerateCallAccessTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GenerateCallAccessTokenMutation, TError, GenerateCallAccessTokenMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<GenerateCallAccessTokenMutation, TError, GenerateCallAccessTokenMutationVariables, TContext>(
      ['GenerateCallAccessToken'],
      (variables?: GenerateCallAccessTokenMutationVariables) => fetcher<GenerateCallAccessTokenMutation, GenerateCallAccessTokenMutationVariables>(client, GenerateCallAccessTokenDocument, variables, headers)(),
      options
    )};

useGenerateCallAccessTokenMutation.getKey = () => ['GenerateCallAccessToken'];


useGenerateCallAccessTokenMutation.fetcher = (client: GraphQLClient, variables: GenerateCallAccessTokenMutationVariables, headers?: RequestInit['headers']) => fetcher<GenerateCallAccessTokenMutation, GenerateCallAccessTokenMutationVariables>(client, GenerateCallAccessTokenDocument, variables, headers);
