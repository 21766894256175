import { dayjs } from '@/client/core/utils/date';
import {
  type UsersPresenceMetaMinecraft,
  type UsersPresenceMetaWeb,
  UsersPresenceMetaWebState,
} from '@/shared/graphql/scalars';

interface GetPresenceStatusStringProps {
  web?: UsersPresenceMetaWeb;
  minecraft?: UsersPresenceMetaMinecraft;
  accountCreated: string;
}

export interface StatusStringData {
  string: string;
  competitionId?: string;
}

export const getPresenceStatusString = ({
  web,
  minecraft,
  accountCreated,
}: GetPresenceStatusStringProps): StatusStringData => {
  if (minecraft) {
    // minecraft data takes precedence over web data
    if (minecraft.online) {
      // if minecraft is online, show some status
      if (minecraft.location === 'lobby') {
        if (minecraft.activity === 'lobby-idle') {
          return r('Idling in the lobby');
        }
      } else if (minecraft.location === 'competition') {
        if (minecraft.activity === 'competition-building') {
          return {
            string: 'Participating in',
            competitionId: minecraft.competition_id,
          };
        } else if (minecraft.activity === 'competition-browsing') {
          return {
            string: 'Browsing in',
            competitionId: minecraft.competition_id,
          };
        } else if (minecraft.activity === 'competition-tutorial') {
          return r('Building in the tutorial project');
        }
      }
      return r('Building in the tutorial project');
    } else {
      // minecraft is offline, check web
      if (!web) {
        // no web data, print last seen on minecraft
        return r(`Last seen ${dayjs(minecraft?.updated_at).fromNow()}`);
      }
      // otherwise has web data, proceed to check web
    }
  }

  if (web) {
    if (web?.state === UsersPresenceMetaWebState.Online) {
      return r('Browsing the website');
    } else if (web?.state === UsersPresenceMetaWebState.Idle) {
      return r('Idling on the website');
    } else if (web?.state === UsersPresenceMetaWebState.Offline) {
      // web is offline. minecraft is either no data or offline
      if (!minecraft) {
        return r(`Last seen ${dayjs(web?.updatedAt).fromNow()}`);
      } else {
        // minecraft is offline but we have data
        // return the last seen of the most recent
        return r(
          `Last seen ${
            dayjs(minecraft?.updated_at).isAfter(web?.updatedAt)
              ? dayjs(minecraft?.updated_at).fromNow()
              : dayjs(web?.updatedAt).fromNow()
          }`
        );
      }
    }
  }

  return r('Last seen ' + dayjs(accountCreated).format('MMM D, YYYY'));
};

// simply returns a string as a StatusStringData object if no other data is needed
const r = (string: string): StatusStringData => ({
  string,
});
