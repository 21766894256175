import { useGetCallChannelQuery } from '@/client/features/calls/operations/generated/get-call-channel.user';
import type { CallChannel } from '@/client/features/calls/operations/get-call-channel';
import { createChannelEvent } from '@/client/features/channels/operations/create-channels-event';
import type { Channel_Type } from '@/generated/graphql/global-types.user';
import hasuraGraphQLClient from '@/shared/graphql/client';
import { type ChannelEventChannel, ChannelEventType } from '@/shared/graphql/schema-extensions/scalars/channels-events';

export const channelEventMessages: Record<
  ChannelEventType,
  (channelType: Channel_Type, channelName?: string) => string
> = {
  [ChannelEventType.JoinVoice]: (channelType, channelName) => `joined the ${channelName} ${channelType}.`,
  [ChannelEventType.ExitVoice]: (channelType, channelName) => `left the ${channelName} ${channelType}.`,
  [ChannelEventType.SwapFromVoice]: (channelType, channelName) => `switched from the ${channelName} ${channelType}.`,
  [ChannelEventType.SwapToVoice]: (channelType, channelName) => `switched to the ${channelName} ${channelType}.`,
  [ChannelEventType.StartSpeaking]: (_channelName) => 'started speaking',
  [ChannelEventType.StopSpeaking]: (_channelName) => 'stopped speaking',
  [ChannelEventType.HandRaised]: (channelType, channelName) =>
    `raised their hand in the ${channelName} ${channelType}.`,
};

export const recordJoinChannelEvents = async (
  fromChannel: undefined | ChannelEventChannel,
  toChannel: ChannelEventChannel,
  userId: undefined | string
) => {
  if (!userId) {
    return;
  }

  if (fromChannel) {
    await Promise.all([
      createChannelEvent(toChannel.id, {
        type: ChannelEventType.SwapFromVoice,
        fromChannel: {
          id: fromChannel.id,
          name: fromChannel.name,
          type: fromChannel.type,
        },
        userId,
      }),
      createChannelEvent(fromChannel.id, {
        type: ChannelEventType.SwapToVoice,
        toChannel: {
          id: toChannel.id,
          name: toChannel.name,
          type: toChannel.type,
        },
        userId,
      }),
    ]);
  } else {
    await createChannelEvent(toChannel.id, {
      type: ChannelEventType.JoinVoice,
      channel: {
        id: toChannel.id,
        name: toChannel.name,
        type: toChannel.type,
      },
      userId,
    });
  }
};

export const recordExitVoiceChannelEvent = async (channel: CallChannel, userId: undefined | string) => {
  if (!userId) {
    return;
  }
  await createChannelEvent(channel.id, {
    type: ChannelEventType.ExitVoice,
    userId,
    channel: {
      id: channel.id,
      name: channel.name,
      type: channel.type,
    },
  });
};

const getChannelById = async (channelId: string) => {
  const { channels_by_pk } = await useGetCallChannelQuery.fetcher(hasuraGraphQLClient.Client, { channelId })();

  return channels_by_pk;
};

export const recordHandRaisedChannelEvent = async (_channel: CallChannel | string, userId: undefined | string) => {
  if (!userId) {
    return;
  }
  const channel = typeof _channel === 'string' ? await getChannelById(_channel) : _channel;

  if (!channel) {
    return;
  }
  await createChannelEvent(channel.id, {
    type: ChannelEventType.HandRaised,
    userId,
    channel: {
      id: channel.id,
      name: channel.name,
      type: channel.type,
    },
  });
};
