import { useCallback, useMemo } from 'react';

import type { AnalyticsService, TrackingCallback } from '@/client/features/analytics/service';

import { useAnalyticsService, useAnalyticsSource } from '../providers';

export const useAnalytics = (): AnalyticsService => {
  const analyticsService = useAnalyticsService();
  const fallbackAnalyticsSource = useAnalyticsSource();

  const trackEventWithFallbackSource = useCallback<TrackingCallback>(
    async (options, context) => {
      const source = options.source ?? (fallbackAnalyticsSource as typeof options.source);
      await analyticsService.trackEvent({ ...options, source }, context);
    },
    [analyticsService, fallbackAnalyticsSource]
  );

  return useMemo(
    () => ({ ...analyticsService, trackEvent: trackEventWithFallbackSource }),
    [analyticsService, trackEventWithFallbackSource]
  );
};
