import { z } from 'zod';

export enum ChoosePlanResult {
  Confirmed = 'confirmed',
  Failed = 'failed',
  Updated = 'updated',
}

export const choosePlanQuerySchema = z.object({
  plan_result: z.nativeEnum(ChoosePlanResult),
  plan_reason: z.string().optional(),
});

export type ChoosePlanQuery = z.infer<typeof choosePlanQuerySchema>;
