import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type UpdateUserWebPresenceMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  web: Types.Scalars['jsonb']['input'];
}>;


export type UpdateUserWebPresenceMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly update_users_by_pk?: Types.Maybe<(
    { readonly __typename?: 'users' }
    & Pick<Types.Users, 'id'>
  )> }
);



export const UpdateUserWebPresenceDocument = `
    mutation UpdateUserWebPresence($id: String!, $web: jsonb!) {
  update_users_by_pk(pk_columns: {id: $id}, _append: {presence: {web: $web}}) {
    id
  }
}
    `;

export const useUpdateUserWebPresenceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserWebPresenceMutation, TError, UpdateUserWebPresenceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateUserWebPresenceMutation, TError, UpdateUserWebPresenceMutationVariables, TContext>(
      ['UpdateUserWebPresence'],
      (variables?: UpdateUserWebPresenceMutationVariables) => fetcher<UpdateUserWebPresenceMutation, UpdateUserWebPresenceMutationVariables>(client, UpdateUserWebPresenceDocument, variables, headers)(),
      options
    )};

useUpdateUserWebPresenceMutation.getKey = () => ['UpdateUserWebPresence'];


useUpdateUserWebPresenceMutation.fetcher = (client: GraphQLClient, variables: UpdateUserWebPresenceMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateUserWebPresenceMutation, UpdateUserWebPresenceMutationVariables>(client, UpdateUserWebPresenceDocument, variables, headers);
