import type { ReactNode } from 'react';

import { Toast } from '@/client/features/toast/components/toast';

interface ToastContainerProps {
  toasts: Record<string, { content: ReactNode; onclose: () => void }>;
  onClose: (identifier: string) => void;
  className?: string;
}

export const ToastContainer = ({ toasts, onClose, className }: ToastContainerProps) => {
  return (
    <div className={className}>
      {Object.keys(toasts).map((identifier, index) => {
        const toast = toasts[identifier]?.content;

        return (
          <Toast
            key={index}
            onClose={() => {
              onClose(identifier);
            }}
          >
            {toast}
          </Toast>
        );
      })}
    </div>
  );
};
