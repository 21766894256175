import React, { forwardRef, type InputHTMLAttributes } from 'react';

import { InputContainer } from '@/client/design-system/components/fields/components/input-container';

export interface CheckboxInputProps
  extends React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  checkmark?: boolean;
  error?: null | string;
  hideError?: boolean;
  label?: string;
  thin?: boolean;
  tooltip?: string;
}

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(function CheckboxInput(props, ref) {
  const { label, tooltip, error, hideError = false, checkmark, className, id, ...inputProps } = props;

  return (
    <InputContainer
      id={id}
      checkmark={checkmark}
      tooltip={tooltip}
      hideError={hideError}
      error={error}
      className={className}
    >
      <div className='flex items-center gap-4'>
        <input {...inputProps} className='aspect-1 size-full' ref={ref} type='checkbox' />
        {label && <label className='whitespace-nowrap text-sm text-light-grey-4'>{label}</label>}
      </div>
    </InputContainer>
  );
});
