// Despite this being an error (i.e. runtime), the intent is we use this to enforce errors during type checking.
export class ExhaustiveCheckError extends Error {
  constructor(_exhaustivelyChecked: never) {
    super(`Unexpected exhaustively type-checked value`);
  }
}

// This allows us to validate we've exhaustively handled cases based on the *current* type, but allows us to account for
// the fact the type we receive may be out of bounds e.g. if the client being executed in a browser is behind and
// doesn't know about a new value.
export function safeExhaustiveCheckFallback<T extends never, F>(_value: T, fallback: F): F;
export function safeExhaustiveCheckFallback<T extends never, F, const I>(_value: T | I, fallback: F, _ignore: I[]): F;
export function safeExhaustiveCheckFallback<T extends never, F, const I>(
  _value: T | I,
  fallback: F,
  _ignore: I[] = []
) {
  return fallback;
}

export type ExhaustiveTypeCheck<T extends never> = T;
