import type { Dictionary } from 'lodash';
import {
  type ComponentType,
  type FunctionComponent,
  type MutableRefObject,
  type Provider,
  type RefCallback,
} from 'react';
import { createContext, useContext } from 'react';

interface StrictContextOptions {
  displayName?: string;
  errorMessage?: string;
}

type StrictContext<T> = [Provider<T>, () => T];

export const createStrictContext = <T>(options?: StrictContextOptions): StrictContext<T> => {
  const Context = createContext<T | undefined>(undefined);
  Context.displayName = options?.displayName;

  const useStrictContext = (): T => {
    const context = useContext(Context);

    if (!context) {
      throw new Error(buildErrorMessage(options));
    }

    return context;
  };

  return [Context.Provider as Provider<T>, useStrictContext];
};

const buildErrorMessage = ({ errorMessage, displayName = 'Context' }: StrictContextOptions = {}): string =>
  errorMessage ?? `${displayName} Provider must be defined in the component tree to be used`;

interface RelaxedContextOptions<T> {
  defaultValue?: T;
  displayName?: string;
}

type RelaxedContext<T> = [Provider<T | undefined>, () => T | undefined];

export const createRelaxedContext = <T>(options?: RelaxedContextOptions<T>): RelaxedContext<T> => {
  const Context = createContext<T | undefined>(options?.defaultValue);
  Context.displayName = options?.displayName;

  const useRelaxedContext = (): T | undefined => useContext(Context);

  return [Context.Provider, useRelaxedContext];
};

export const createCompoundComponent = <P extends FunctionComponent<any>, C extends Dictionary<ComponentType<any>>>(
  parent: P,
  children: C
): P & C => {
  const parentCopy = parent.bind({}) as P & C;
  return Object.assign(parentCopy, children);
};

export type MergeableRef<T> = RefCallback<T> | MutableRefObject<null | T>;
export type MergedRef<
  A extends MergeableRef<any>,
  B extends undefined | null | MergeableRef<ExtractMergeableRefType<A>>,
> = B extends RefCallback<any> | MutableRefObject<any> ? RefCallback<ExtractMergeableRefType<A>> : A;
export type ExtractMergeableRefType<R extends undefined | null | MergeableRef<any>> = R extends (
  instance: null | infer T
) => void
  ? T
  : R extends { current: null | infer T }
    ? T
    : never;

export const mergeRefs = <
  T,
  A extends MergeableRef<T>,
  B extends undefined | null | MergeableRef<ExtractMergeableRefType<A>>,
>(
  a: A,
  b: B
): MergedRef<A, B> => {
  if (!b) {
    return a as MergedRef<A, B>;
  }

  return ((element: null | ExtractMergeableRefType<A>) => {
    if (typeof a === 'function') {
      a(element);
    } else if (a) {
      a.current = element;
    }

    if (typeof b === 'function') {
      b(element);
    } else if (b) {
      b.current = element;
    }
  }) as MergedRef<A, B>;
};
