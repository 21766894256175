'use client';

import { AnimatePresence } from 'framer-motion';
import { type ReactNode, useCallback } from 'react';
import { useMapState } from 'rooks';

import { ToastContainer } from '@/client/features/toast/components/toast-container';

import type { Callback } from '../../../core/types/react';
import { createStrictContext } from '../../../core/utils/react';
import { SimpleToast } from '../components/simple-toast';
import type { ISimpleToast } from '../types/simple-toast';

interface IToastContext {
  showToast: Callback<[ReactNode, () => void]>;
  showSimpleToast: (toast: ISimpleToast) => void;
  clearToast: () => void;
}

const TOAST_TIMEOUT_IN_SECONDS = 20;

const [ToastContext, useToast] = createStrictContext<IToastContext>();

export function ToastProvider({ children }: any): JSX.Element {
  const [toasts, toastMapControls] = useMapState<
    Record<
      string,
      {
        content: ReactNode;
        onclose: () => void;
      }
    >,
    string
  >({});
  const { set, remove, removeAll } = toastMapControls;

  const showToast = useCallback(
    (toast: ReactNode, onclose = () => {}, timeoutMs = TOAST_TIMEOUT_IN_SECONDS) => {
      const identifier = `${Date.now()}-${Math.random() * 100}`;

      const timeout = setTimeout(() => {
        onclose?.();
        remove(identifier);
      }, timeoutMs * 1000);

      const oncloseWithCleanup = (): void => {
        clearTimeout(timeout);
        onclose?.();
      };

      set(identifier, { content: toast, onclose: oncloseWithCleanup });
    },
    [remove, set]
  );

  const showSimpleToast = useCallback(
    ({ title, description, color, onClick, timeout }: ISimpleToast): void => {
      const ToastContent = (
        <SimpleToast title={title} description={description} color={color} onClick={onClick} timeout={timeout} />
      );

      showToast(ToastContent, undefined, timeout);
    },
    [showToast]
  );

  const contextValue = {
    showToast,
    showSimpleToast,
    clearToast: removeAll,
  };

  const handleToastClose = (identifier: string): void => {
    toasts[identifier]?.onclose();
    remove(identifier);
  };

  return (
    <ToastContext value={contextValue}>
      {children}

      <AnimatePresence>
        <ToastContainer
          className='fixed right-5 top-[84px] z-max m-0 flex w-full max-w-[320px] flex-col-reverse gap-3'
          toasts={toasts}
          onClose={handleToastClose}
        />
      </AnimatePresence>
    </ToastContext>
  );
}

export { useToast };
