import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type SetChannelPresenceMutationVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
  status: Types.Channel_User_Status;
}>;


export type SetChannelPresenceMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly update_channels_users?: Types.Maybe<(
    { readonly __typename?: 'channels_users_mutation_response' }
    & Pick<Types.Channels_Users_Mutation_Response, 'affected_rows'>
  )> }
);



export const SetChannelPresenceDocument = `
    mutation SetChannelPresence($channelId: uuid!, $status: channel_user_status!) {
  update_channels_users(
    where: {channel_id: {_eq: $channelId}, user: {current: {_eq: true}}}
    _set: {status: $status}
  ) {
    affected_rows
  }
}
    `;

export const useSetChannelPresenceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SetChannelPresenceMutation, TError, SetChannelPresenceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SetChannelPresenceMutation, TError, SetChannelPresenceMutationVariables, TContext>(
      ['SetChannelPresence'],
      (variables?: SetChannelPresenceMutationVariables) => fetcher<SetChannelPresenceMutation, SetChannelPresenceMutationVariables>(client, SetChannelPresenceDocument, variables, headers)(),
      options
    )};

useSetChannelPresenceMutation.getKey = () => ['SetChannelPresence'];


useSetChannelPresenceMutation.fetcher = (client: GraphQLClient, variables: SetChannelPresenceMutationVariables, headers?: RequestInit['headers']) => fetcher<SetChannelPresenceMutation, SetChannelPresenceMutationVariables>(client, SetChannelPresenceDocument, variables, headers);
