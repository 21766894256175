import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { type GraphQLClient } from 'graphql-request';

import type {
  GetCallChannelQuery,
  GetCallChannelQueryVariables,
} from '@/client/features/calls/operations/generated/get-call-channel.user';
import { useGetCallChannelQuery } from '@/client/features/calls/operations/generated/get-call-channel.user';
import {
  type ChannelAssociatedObject,
  ChannelAssociatedObjectType,
  getAssociatedObject,
} from '@/client/features/channels/associated-objects';
import hasuraGraphQLClient from '@/shared/graphql/client';
import type { ChannelsMute } from '@/shared/graphql/schema-extensions/scalars/channels-mute';

export type GetCallChannelVariables = GetCallChannelQueryVariables;

type RawCallChannel = NonNullable<GetCallChannelQuery['channels_by_pk']>;

export type CallChannel = Omit<RawCallChannel, '__typename' | `${ChannelAssociatedObjectType}`> & {
  associatedObject: null | ChannelAssociatedObject;
  mute: ChannelsMute;
};

function callChannelFromRawCallChannel(rawChannel: RawCallChannel): CallChannel {
  const { __typename, ...channelFields } = rawChannel;
  const associatedObject = getAssociatedObject(rawChannel);

  for (const key of Object.values(ChannelAssociatedObjectType)) {
    delete channelFields[key];
  }

  return Object.assign(channelFields, { associatedObject, mute: rawChannel.mute });
}

export function fetcher(client: GraphQLClient, variables: GetCallChannelVariables) {
  return async function (): Promise<null | CallChannel> {
    const { channels_by_pk: rawChannel } = await useGetCallChannelQuery.fetcher(client, variables)();

    if (!rawChannel) {
      return rawChannel ?? null;
    }
    return callChannelFromRawCallChannel(rawChannel);
  };
}

export const getKey = useGetCallChannelQuery.getKey;

export const useGetCallChannel = (
  variables: GetCallChannelVariables,
  options?: UseQueryOptions<null | CallChannel, unknown, null | CallChannel>
) => {
  useQuery<null | CallChannel, unknown>(
    ['GetCallChannel', variables],
    fetcher(hasuraGraphQLClient.Client, variables),
    options
  );
};
