import { useDevices } from '@daily-co/daily-react';
import { useCallback, useMemo } from 'react';

import { setLocalStorageItem } from '@/shared/utils/browser';

export const useMediaDevices = () => {
  const {
    refreshDevices,
    setSpeaker,
    setCamera,
    setMicrophone,
    currentSpeaker,
    currentMic,
    currentCam,
    speakers,
    microphones,
    cameras,
  } = useDevices();

  const updateAudioOutputDevice = useCallback(
    async (deviceId: string) => {
      setLocalStorageItem('audioOutputDeviceId', deviceId);

      await setSpeaker(deviceId);
      await refreshDevices();
    },
    [refreshDevices, setSpeaker]
  );

  const updateAudioInputDevice = useCallback(
    async (deviceId: string) => {
      setLocalStorageItem('audioInputDeviceId', deviceId);

      await setMicrophone(deviceId);
      await refreshDevices();
    },
    [refreshDevices, setMicrophone]
  );

  const updateVideoInputDevice = useCallback(
    async (deviceId: string) => {
      setLocalStorageItem('videoInputDeviceId', deviceId);

      await setCamera(deviceId);
      await refreshDevices();
    },
    [refreshDevices, setCamera]
  );

  const audioInputDeviceList = useMemo(() => {
    return microphones.map((microphone) => microphone.device);
  }, [microphones]);

  const audioOutputDeviceList = useMemo(() => {
    return speakers.map((speaker) => speaker.device);
  }, [speakers]);

  const videoInputDeviceList = useMemo(() => {
    return cameras.map((camera) => camera.device);
  }, [cameras]);

  return {
    updateAudioInputDevice,
    updateAudioOutputDevice,
    updateVideoInputDevice,
    audioInputDeviceList,
    audioOutputDeviceList,
    videoInputDeviceList,
    audioInputDevice: currentMic?.device,
    audioOutputDevice: currentSpeaker?.device,
    videoInputDevice: currentCam?.device,
  };
};
