import { useEffectOnceWhen } from 'rooks';

import type { ApiUser } from '@/api/auth/user';

import { useAnalytics } from './use-analytics';

export const useIdentifyOnPageView = (maybeUser?: ApiUser): void => {
  const { identifyUser } = useAnalytics();
  useEffectOnceWhen(() => {
    const user = maybeUser!;
    void identifyUser({
      object: 'page',
      action: 'loaded',
      userId: user.id,
      properties: {
        username: user.username ?? undefined,
        photo: user.photo,
        role: user.role,
      },
    });
  }, !!maybeUser);
};
