import { useCallback, useEffect, useState } from 'react';

import { ChannelAssociatedObjectType } from '@/client/features/channels/associated-objects';
import { FeedbackModal } from '@/client/features/feedback/components/feedback-modal';
import { Room_Status } from '@/generated/graphql/global-types.user';
import { useCall } from '@/providers/daily/call/call-provider';

const Title = 'How was the call?';

const BadQuestion = "What didn't you like about this call?";

export const RoomFeedbackModal = () => {
  const { currentChannel } = useCall();

  const [callAssociationId, setCallAssociationId] = useState<null | string>(null);
  const [participated, setParticipated] = useState(false);

  const [open, setOpen] = useState(false);

  const isCallActive = !!currentChannel?.id;
  const room =
    currentChannel?.associatedObject && currentChannel.associatedObject.type === ChannelAssociatedObjectType.Room
      ? currentChannel.associatedObject.object
      : null;
  const isRoomOpen = room?.status === Room_Status.Open;

  const { association_id: associationId, feedback_question: feedbackQuestion } = room || {};

  useEffect(() => {
    if (isCallActive) {
      setCallAssociationId(associationId || null);
    }
  }, [associationId, isCallActive]);

  useEffect(() => {
    if (isCallActive && isRoomOpen) {
      setParticipated(true);
    }
  }, [isCallActive, isRoomOpen]);

  useEffect(() => {
    if (!isCallActive && participated) {
      setOpen(true);
    }
  }, [isCallActive, participated]);

  const onClose = useCallback(() => {
    setParticipated(false);
    setOpen(false);
  }, []);

  return callAssociationId ? (
    <FeedbackModal
      associatedObject={callAssociationId}
      badQuestion={BadQuestion}
      goodQuestion={feedbackQuestion}
      neutralQuestion={feedbackQuestion}
      onClose={onClose}
      open={open}
      title={Title}
    />
  ) : (
    <></>
  );
};
