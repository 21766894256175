import React, { type FocusEventHandler, forwardRef, type InputHTMLAttributes, useCallback, useState } from 'react';

import { FloatingLabel } from '@/client/design-system/components/fields/components/floating-label';
import { InputContainer } from '@/client/design-system/components/fields/components/input-container';

import { cn } from '../../utils/tailwind';

export interface DatetimeInputProps
  extends React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  checkmark?: boolean;
  error?: null | string;
  hideError?: boolean;
  label?: string;
  thin?: boolean;
  tooltip?: string;
}

export const DatetimeInput = forwardRef<HTMLInputElement, DatetimeInputProps>(function DatetimeInput(props, ref) {
  const {
    checkmark,
    className,
    disabled = false,
    error,
    hideError = false,
    id,
    label,
    onBlur,
    onFocus,
    tooltip,
    ...inputProps
  } = props;

  const [focus, setFocus] = useState(false);

  const innerOnFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setFocus(true);
      onFocus?.(event);
    },
    [onFocus]
  );

  const innerOnBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setFocus(false);
      onBlur?.(event);
    },
    [onBlur]
  );

  return (
    <InputContainer
      id={id}
      checkmark={checkmark}
      tooltip={tooltip}
      hideError={hideError}
      error={error}
      className={className}
    >
      {label && <FloatingLabel label={label} focus={focus} hasError={!!error} small />}

      <input
        {...inputProps}
        className={cn(
          'h-14 w-full rounded-sm border bg-dark-grey-1 px-3.6 pt-4 text-paragraph3 font-medium outline-none transition-colors duration-100 placeholder:text-transparent disabled:opacity-50',
          !error && !focus && !disabled && 'group-hover:border-disabled-1',
          error ? 'border-accent-red/40' : 'border-disabled-2 focus:border-accent/40'
        )}
        disabled={disabled}
        onBlur={innerOnBlur}
        onFocus={innerOnFocus}
        ref={ref}
        type='datetime-local'
      />
    </InputContainer>
  );
});
