import { twMerge } from 'tailwind-merge';

import { Icon } from '@/client/design-system/components/icon';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/client/design-system/components/tooltip';

interface InputContainerProps extends React.HTMLAttributes<any> {
  checkmark?: boolean;
  tooltip?: string;
  hideError?: boolean;
  error?: any;
}

export const InputContainer = ({
  id,
  className,
  children,
  checkmark,
  tooltip,
  hideError,
  error,
}: InputContainerProps) => {
  return (
    <div id={id} className={twMerge('relative', className)}>
      <div className='group flex flex-col justify-center'>{children}</div>
      {checkmark && (
        <span className='absolute right-2 top-4'>
          <Icon size={24} icon='check' color='#00FF47' />
        </span>
      )}
      {tooltip && (
        <div className='absolute -right-8 top-0'>
          <Tooltip>
            <TooltipTrigger
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Icon icon='information' color='#ebebeb' size={20} className='inline opacity-50' />
            </TooltipTrigger>
            <TooltipContent side='right' sideOffset={4}>
              {tooltip}
            </TooltipContent>
          </Tooltip>
        </div>
      )}
      {!hideError && error && (
        <div className='mt-1.5 pl-4 text-left text-caption1 text-accent-red'>{error ?? '\uFEFF'}</div>
      )}
    </div>
  );
};
