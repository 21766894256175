import { useRouter as usePagesRouter } from 'next/compat/router';
import { usePathname, useRouter as useAppRouter, useSearchParams } from 'next/navigation';

export const useRouter = () => {
  const pagesRouter = usePagesRouter();
  const appRouter = useAppRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  if (pagesRouter) {
    return pagesRouter;
  }
  const query: Record<string, string | undefined> = {};

  if (searchParams && 'keys' in searchParams) {
    for (const key of searchParams.keys()) {
      query[key] = searchParams.get(key) ?? undefined;
    }
  }
  return {
    pathname,
    query,
    reload: appRouter.refresh,
    ...appRouter,
  };
};

export type NextCompatibleRouter = ReturnType<typeof useRouter>;
