import type { CSS, PropertyValue } from '@stitches/react';

import { createUtilityVariant } from './theme-utilities';

export const scrollbar = createUtilityVariant({
  visible: {
    scrollbarColor: 'transparent rgba(255, 255, 255, .2)',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      borderRadius: 30,
    },

    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, .2)',
      },
    },

    '&::-webkit-scrollbar': {
      width: 6,
    },

    '&::-webkit-scrollbar *': {
      background: 'transparent',
    },
  },
  hidden: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
});

export const scrollbarMarginBlock = (marginBlock: PropertyValue<'marginBlock'>): CSS => ({
  '&::-webkit-scrollbar-track-piece:start': {
    marginBlockStart: marginBlock,
  },

  '&::-webkit-scrollbar-track-piece:end': {
    marginBlockEnd: marginBlock,
  },
});
