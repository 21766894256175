import { useCallback, useEffect, useState } from 'react';

const events = ['mousedown', 'touchstart'];

export default function useInteraction(): boolean {
  const [ready, setReady] = useState(false);

  const listener = useCallback(() => {
    if (!ready) {
      setReady(true);
    }
  }, [ready]);

  useEffect(() => {
    events.forEach((event) => {
      document.addEventListener(event, listener);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, listener);
      });
    };
  }, [listener]);

  return ready;
}
