import { clsx } from 'clsx';

import { Icon } from '@/client/design-system/components/icon';

import type { ISimpleToast } from '../types/simple-toast';

const variants = {
  default: 'shadow-[0_0_0_1px_rgba(0,0,0,0.05),0_1px_1px_0_rgba(0,0,0,0.05),0_4px_16px_-8px_rgba(0,0,0,0.20)]',
  success: 'shadow-[0_0_0_2px_rgba(8,183,141,0.10),0_1px_1px_0_rgba(0,0,0,0.05)] backdrop-blur-lg',
  danger: 'shadow-[0_0_0_2px_rgb(248,65,65),0_1px_1px_0_rgba(0,0,0,0.05)] backdrop-blur-lg',
};

export const SimpleToast = ({ title, description, color = 'default', onClick }: ISimpleToast): JSX.Element => {
  return (
    <div
      className={clsx(
        'rounded-xl py-3 px-4 bg-page-item-surface max-w-[320px] w-full pr-8',
        onClick && 'cursor-pointer',
        variants[color]
      )}
      onClick={onClick}
    >
      <div className='relative flex items-center gap-3'>
        {color === 'success' ? (
          <Icon icon='circle-check' className='shrink-0 text-positive' size={16} />
        ) : (
          color === 'danger' && <Icon icon='circle-cross' className='shrink-0 text-destructive' size={16} />
        )}

        <div className='flex flex-col'>
          {title && <span className='text-regular-plus text-primary'>{title}</span>}
          {description && <span className='text-small text-secondary'>{description}</span>}
        </div>
      </div>
    </div>
  );
};
