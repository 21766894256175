import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type RoomDetailFragment = (
  { readonly __typename?: 'rooms' }
  & Pick<Types.Rooms, 'association_id' | 'color' | 'hosts' | 'id' | 'kind' | 'name' | 'promo_image_url' | 'status' | 'tagline' | 'url_name' | 'owner_id' | 'lock_time' | 'unlock_time' | 'feedback_question' | 'clipboard_prompt'>
  & { readonly club: (
    { readonly __typename?: 'clubs' }
    & Pick<Types.Clubs, 'id' | 'name' | 'url_name'>
    & { readonly members: ReadonlyArray<(
      { readonly __typename?: 'clubs_members' }
      & Pick<Types.Clubs_Members, 'onboarded'>
    )> }
  ), readonly channel?: Types.Maybe<(
    { readonly __typename?: 'channels' }
    & Pick<Types.Channels, 'id' | 'association_id' | 'user_status_platform_states'>
  )>, readonly default_room_section: ReadonlyArray<(
    { readonly __typename?: 'rooms_sections' }
    & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
    & { readonly breakouts: ReadonlyArray<(
      { readonly __typename?: 'breakouts' }
      & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
      & { readonly room_section?: Types.Maybe<(
        { readonly __typename?: 'rooms_sections' }
        & Pick<Types.Rooms_Sections, 'room_id'>
      )> }
    )> }
  )> }
);

export type BreakoutRoomDetailFragment = (
  { readonly __typename?: 'rooms' }
  & Pick<Types.Rooms, 'association_id' | 'color' | 'hosts' | 'id' | 'kind' | 'name' | 'promo_image_url' | 'status' | 'tagline' | 'url_name' | 'owner_id'>
  & { readonly club: (
    { readonly __typename?: 'clubs' }
    & Pick<Types.Clubs, 'id' | 'name' | 'url_name'>
    & { readonly members: ReadonlyArray<(
      { readonly __typename?: 'clubs_members' }
      & Pick<Types.Clubs_Members, 'onboarded'>
    )> }
  ), readonly channel?: Types.Maybe<(
    { readonly __typename?: 'channels' }
    & Pick<Types.Channels, 'id' | 'association_id' | 'user_status_platform_states'>
  )>, readonly default_room_section: ReadonlyArray<(
    { readonly __typename?: 'rooms_sections' }
    & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
    & { readonly breakouts: ReadonlyArray<(
      { readonly __typename?: 'breakouts' }
      & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
      & { readonly room_section?: Types.Maybe<(
        { readonly __typename?: 'rooms_sections' }
        & Pick<Types.Rooms_Sections, 'room_id'>
      )> }
    )> }
  )> }
);

export type GetRoomDetailQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type GetRoomDetailQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms_by_pk?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'association_id' | 'color' | 'hosts' | 'id' | 'kind' | 'name' | 'promo_image_url' | 'status' | 'tagline' | 'url_name' | 'owner_id' | 'lock_time' | 'unlock_time' | 'feedback_question' | 'clipboard_prompt'>
    & { readonly club: (
      { readonly __typename?: 'clubs' }
      & Pick<Types.Clubs, 'id' | 'name' | 'url_name'>
      & { readonly members: ReadonlyArray<(
        { readonly __typename?: 'clubs_members' }
        & Pick<Types.Clubs_Members, 'onboarded'>
      )> }
    ), readonly channel?: Types.Maybe<(
      { readonly __typename?: 'channels' }
      & Pick<Types.Channels, 'id' | 'association_id' | 'user_status_platform_states'>
    )>, readonly default_room_section: ReadonlyArray<(
      { readonly __typename?: 'rooms_sections' }
      & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
      & { readonly breakouts: ReadonlyArray<(
        { readonly __typename?: 'breakouts' }
        & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
        & { readonly room_section?: Types.Maybe<(
          { readonly __typename?: 'rooms_sections' }
          & Pick<Types.Rooms_Sections, 'room_id'>
        )> }
      )> }
    )> }
  )> }
);

export type GetRoomDetailWithBreakoutQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
  breakoutId: Types.Scalars['uuid']['input'];
}>;


export type GetRoomDetailWithBreakoutQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms_by_pk?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'association_id' | 'color' | 'hosts' | 'id' | 'kind' | 'name' | 'promo_image_url' | 'status' | 'tagline' | 'url_name' | 'owner_id'>
    & { readonly club: (
      { readonly __typename?: 'clubs' }
      & Pick<Types.Clubs, 'id' | 'name' | 'url_name'>
      & { readonly members: ReadonlyArray<(
        { readonly __typename?: 'clubs_members' }
        & Pick<Types.Clubs_Members, 'onboarded'>
      )> }
    ), readonly channel?: Types.Maybe<(
      { readonly __typename?: 'channels' }
      & Pick<Types.Channels, 'id' | 'association_id' | 'user_status_platform_states'>
    )>, readonly default_room_section: ReadonlyArray<(
      { readonly __typename?: 'rooms_sections' }
      & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
      & { readonly breakouts: ReadonlyArray<(
        { readonly __typename?: 'breakouts' }
        & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
        & { readonly room_section?: Types.Maybe<(
          { readonly __typename?: 'rooms_sections' }
          & Pick<Types.Rooms_Sections, 'room_id'>
        )> }
      )> }
    )> }
  )> }
);

export type GetRoomDetailByUrlNameQueryVariables = Types.Exact<{
  clubUrlName: Types.Scalars['String']['input'];
  urlName: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type GetRoomDetailByUrlNameQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms: ReadonlyArray<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'association_id' | 'color' | 'hosts' | 'id' | 'kind' | 'name' | 'promo_image_url' | 'status' | 'tagline' | 'url_name' | 'owner_id' | 'lock_time' | 'unlock_time' | 'feedback_question' | 'clipboard_prompt'>
    & { readonly club: (
      { readonly __typename?: 'clubs' }
      & Pick<Types.Clubs, 'id' | 'name' | 'url_name'>
      & { readonly members: ReadonlyArray<(
        { readonly __typename?: 'clubs_members' }
        & Pick<Types.Clubs_Members, 'onboarded'>
      )> }
    ), readonly channel?: Types.Maybe<(
      { readonly __typename?: 'channels' }
      & Pick<Types.Channels, 'id' | 'association_id' | 'user_status_platform_states'>
    )>, readonly default_room_section: ReadonlyArray<(
      { readonly __typename?: 'rooms_sections' }
      & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
      & { readonly breakouts: ReadonlyArray<(
        { readonly __typename?: 'breakouts' }
        & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
        & { readonly room_section?: Types.Maybe<(
          { readonly __typename?: 'rooms_sections' }
          & Pick<Types.Rooms_Sections, 'room_id'>
        )> }
      )> }
    )> }
  )> }
);

export type GetRoomDetailByUrlNameWithBreakoutQueryVariables = Types.Exact<{
  clubUrlName: Types.Scalars['String']['input'];
  urlName: Types.Scalars['String']['input'];
  userId: Types.Scalars['String']['input'];
  breakoutId: Types.Scalars['uuid']['input'];
}>;


export type GetRoomDetailByUrlNameWithBreakoutQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms: ReadonlyArray<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'association_id' | 'color' | 'hosts' | 'id' | 'kind' | 'name' | 'promo_image_url' | 'status' | 'tagline' | 'url_name' | 'owner_id'>
    & { readonly club: (
      { readonly __typename?: 'clubs' }
      & Pick<Types.Clubs, 'id' | 'name' | 'url_name'>
      & { readonly members: ReadonlyArray<(
        { readonly __typename?: 'clubs_members' }
        & Pick<Types.Clubs_Members, 'onboarded'>
      )> }
    ), readonly channel?: Types.Maybe<(
      { readonly __typename?: 'channels' }
      & Pick<Types.Channels, 'id' | 'association_id' | 'user_status_platform_states'>
    )>, readonly default_room_section: ReadonlyArray<(
      { readonly __typename?: 'rooms_sections' }
      & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
      & { readonly breakouts: ReadonlyArray<(
        { readonly __typename?: 'breakouts' }
        & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'channel_id'>
        & { readonly room_section?: Types.Maybe<(
          { readonly __typename?: 'rooms_sections' }
          & Pick<Types.Rooms_Sections, 'room_id'>
        )> }
      )> }
    )> }
  )> }
);


export const RoomDetailFragmentDoc = `
    fragment RoomDetail on rooms {
  association_id
  color
  hosts
  id
  kind
  name
  promo_image_url
  status
  tagline
  url_name
  owner_id
  lock_time
  unlock_time
  feedback_question
  clipboard_prompt
  club {
    id
    name
    url_name
    members(where: {user_id: {_eq: $userId}}) {
      onboarded
    }
  }
  channel {
    id
    association_id
    user_status_platform_states
  }
  default_room_section: rooms_sections(limit: 1, order_by: {sort_order: asc}) {
    id
    name
    image_url
    icon_image_url
    sort_order
    association_id
    room_id
    color
    type
    platform_settings
    created_at
    breakouts(limit: 1, order_by: {created_at: asc}) {
      id
      name
      type
      sort_order
      platform_settings
      room_section {
        room_id
      }
      channel_id
    }
  }
}
    `;
export const BreakoutRoomDetailFragmentDoc = `
    fragment BreakoutRoomDetail on rooms {
  association_id
  color
  hosts
  id
  kind
  name
  promo_image_url
  status
  tagline
  url_name
  owner_id
  club {
    id
    name
    url_name
    members(where: {user_id: {_eq: $userId}}) {
      onboarded
    }
  }
  channel {
    id
    association_id
    user_status_platform_states
  }
  default_room_section: rooms_sections(
    limit: 1
    order_by: {sort_order: asc}
    where: {breakouts: {id: {_eq: $breakoutId}}}
  ) {
    id
    name
    image_url
    icon_image_url
    sort_order
    association_id
    room_id
    color
    type
    platform_settings
    created_at
    breakouts(limit: 1, order_by: {created_at: asc}) {
      id
      name
      type
      sort_order
      platform_settings
      room_section {
        room_id
      }
      channel_id
    }
  }
}
    `;
export const GetRoomDetailDocument = `
    query GetRoomDetail($roomId: uuid!, $userId: String!) {
  rooms_by_pk(id: $roomId) {
    ...RoomDetail
  }
}
    ${RoomDetailFragmentDoc}`;

export const useGetRoomDetailQuery = <
      TData = GetRoomDetailQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomDetailQueryVariables,
      options?: UseQueryOptions<GetRoomDetailQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRoomDetailQuery, TError, TData>(
      ['GetRoomDetail', variables],
      fetcher<GetRoomDetailQuery, GetRoomDetailQueryVariables>(client, GetRoomDetailDocument, variables, headers),
      options
    )};

useGetRoomDetailQuery.getKey = (variables: GetRoomDetailQueryVariables) => ['GetRoomDetail', variables];

export const useInfiniteGetRoomDetailQuery = <
      TData = GetRoomDetailQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomDetailQueryVariables,
      options?: UseInfiniteQueryOptions<GetRoomDetailQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetRoomDetailQuery, TError, TData>(
      ['GetRoomDetail.infinite', variables],
      (metaData) => fetcher<GetRoomDetailQuery, GetRoomDetailQueryVariables>(client, GetRoomDetailDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetRoomDetailQuery.getKey = (variables: GetRoomDetailQueryVariables) => ['GetRoomDetail.infinite', variables];


useGetRoomDetailQuery.fetcher = (client: GraphQLClient, variables: GetRoomDetailQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRoomDetailQuery, GetRoomDetailQueryVariables>(client, GetRoomDetailDocument, variables, headers);

export const GetRoomDetailWithBreakoutDocument = `
    query GetRoomDetailWithBreakout($roomId: uuid!, $userId: String!, $breakoutId: uuid!) {
  rooms_by_pk(id: $roomId) {
    ...BreakoutRoomDetail
  }
}
    ${BreakoutRoomDetailFragmentDoc}`;

export const useGetRoomDetailWithBreakoutQuery = <
      TData = GetRoomDetailWithBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomDetailWithBreakoutQueryVariables,
      options?: UseQueryOptions<GetRoomDetailWithBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRoomDetailWithBreakoutQuery, TError, TData>(
      ['GetRoomDetailWithBreakout', variables],
      fetcher<GetRoomDetailWithBreakoutQuery, GetRoomDetailWithBreakoutQueryVariables>(client, GetRoomDetailWithBreakoutDocument, variables, headers),
      options
    )};

useGetRoomDetailWithBreakoutQuery.getKey = (variables: GetRoomDetailWithBreakoutQueryVariables) => ['GetRoomDetailWithBreakout', variables];

export const useInfiniteGetRoomDetailWithBreakoutQuery = <
      TData = GetRoomDetailWithBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomDetailWithBreakoutQueryVariables,
      options?: UseInfiniteQueryOptions<GetRoomDetailWithBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetRoomDetailWithBreakoutQuery, TError, TData>(
      ['GetRoomDetailWithBreakout.infinite', variables],
      (metaData) => fetcher<GetRoomDetailWithBreakoutQuery, GetRoomDetailWithBreakoutQueryVariables>(client, GetRoomDetailWithBreakoutDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetRoomDetailWithBreakoutQuery.getKey = (variables: GetRoomDetailWithBreakoutQueryVariables) => ['GetRoomDetailWithBreakout.infinite', variables];


useGetRoomDetailWithBreakoutQuery.fetcher = (client: GraphQLClient, variables: GetRoomDetailWithBreakoutQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRoomDetailWithBreakoutQuery, GetRoomDetailWithBreakoutQueryVariables>(client, GetRoomDetailWithBreakoutDocument, variables, headers);

export const GetRoomDetailByUrlNameDocument = `
    query GetRoomDetailByUrlName($clubUrlName: String!, $urlName: String!, $userId: String!) {
  rooms(where: {url_name: {_eq: $urlName}, club: {url_name: {_eq: $clubUrlName}}}) {
    ...RoomDetail
  }
}
    ${RoomDetailFragmentDoc}`;

export const useGetRoomDetailByUrlNameQuery = <
      TData = GetRoomDetailByUrlNameQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomDetailByUrlNameQueryVariables,
      options?: UseQueryOptions<GetRoomDetailByUrlNameQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRoomDetailByUrlNameQuery, TError, TData>(
      ['GetRoomDetailByUrlName', variables],
      fetcher<GetRoomDetailByUrlNameQuery, GetRoomDetailByUrlNameQueryVariables>(client, GetRoomDetailByUrlNameDocument, variables, headers),
      options
    )};

useGetRoomDetailByUrlNameQuery.getKey = (variables: GetRoomDetailByUrlNameQueryVariables) => ['GetRoomDetailByUrlName', variables];

export const useInfiniteGetRoomDetailByUrlNameQuery = <
      TData = GetRoomDetailByUrlNameQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomDetailByUrlNameQueryVariables,
      options?: UseInfiniteQueryOptions<GetRoomDetailByUrlNameQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetRoomDetailByUrlNameQuery, TError, TData>(
      ['GetRoomDetailByUrlName.infinite', variables],
      (metaData) => fetcher<GetRoomDetailByUrlNameQuery, GetRoomDetailByUrlNameQueryVariables>(client, GetRoomDetailByUrlNameDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetRoomDetailByUrlNameQuery.getKey = (variables: GetRoomDetailByUrlNameQueryVariables) => ['GetRoomDetailByUrlName.infinite', variables];


useGetRoomDetailByUrlNameQuery.fetcher = (client: GraphQLClient, variables: GetRoomDetailByUrlNameQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRoomDetailByUrlNameQuery, GetRoomDetailByUrlNameQueryVariables>(client, GetRoomDetailByUrlNameDocument, variables, headers);

export const GetRoomDetailByUrlNameWithBreakoutDocument = `
    query GetRoomDetailByUrlNameWithBreakout($clubUrlName: String!, $urlName: String!, $userId: String!, $breakoutId: uuid!) {
  rooms(where: {url_name: {_eq: $urlName}, club: {url_name: {_eq: $clubUrlName}}}) {
    ...BreakoutRoomDetail
  }
}
    ${BreakoutRoomDetailFragmentDoc}`;

export const useGetRoomDetailByUrlNameWithBreakoutQuery = <
      TData = GetRoomDetailByUrlNameWithBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomDetailByUrlNameWithBreakoutQueryVariables,
      options?: UseQueryOptions<GetRoomDetailByUrlNameWithBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRoomDetailByUrlNameWithBreakoutQuery, TError, TData>(
      ['GetRoomDetailByUrlNameWithBreakout', variables],
      fetcher<GetRoomDetailByUrlNameWithBreakoutQuery, GetRoomDetailByUrlNameWithBreakoutQueryVariables>(client, GetRoomDetailByUrlNameWithBreakoutDocument, variables, headers),
      options
    )};

useGetRoomDetailByUrlNameWithBreakoutQuery.getKey = (variables: GetRoomDetailByUrlNameWithBreakoutQueryVariables) => ['GetRoomDetailByUrlNameWithBreakout', variables];

export const useInfiniteGetRoomDetailByUrlNameWithBreakoutQuery = <
      TData = GetRoomDetailByUrlNameWithBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRoomDetailByUrlNameWithBreakoutQueryVariables,
      options?: UseInfiniteQueryOptions<GetRoomDetailByUrlNameWithBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetRoomDetailByUrlNameWithBreakoutQuery, TError, TData>(
      ['GetRoomDetailByUrlNameWithBreakout.infinite', variables],
      (metaData) => fetcher<GetRoomDetailByUrlNameWithBreakoutQuery, GetRoomDetailByUrlNameWithBreakoutQueryVariables>(client, GetRoomDetailByUrlNameWithBreakoutDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetRoomDetailByUrlNameWithBreakoutQuery.getKey = (variables: GetRoomDetailByUrlNameWithBreakoutQueryVariables) => ['GetRoomDetailByUrlNameWithBreakout.infinite', variables];


useGetRoomDetailByUrlNameWithBreakoutQuery.fetcher = (client: GraphQLClient, variables: GetRoomDetailByUrlNameWithBreakoutQueryVariables, headers?: RequestInit['headers']) => fetcher<GetRoomDetailByUrlNameWithBreakoutQuery, GetRoomDetailByUrlNameWithBreakoutQueryVariables>(client, GetRoomDetailByUrlNameWithBreakoutDocument, variables, headers);
