'use client';

import type { DailyCallOptions, DailyEventObject } from '@daily-co/daily-js';
import { useDaily, useDailyEvent, useNetwork } from '@daily-co/daily-react';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useIntervalWhen } from 'rooks';

import { useGraphQLSubscription } from '@/client/core/hooks/use-graphql-subscription';
import { usePrevious } from '@/client/core/hooks/use-previous';
import { useStableCallback } from '@/client/core/hooks/use-stable-callback';
import changeSfx from '@/client/design-system/sounds/change-channel.mp3';
import joinSfx from '@/client/design-system/sounds/join-channel.mp3';
import leaveSfx from '@/client/design-system/sounds/leave-channel.mp3';
import muteSfx from '@/client/design-system/sounds/mute.mp3';
import unmuteSfx from '@/client/design-system/sounds/unmute.mp3';
import type { Breakout } from '@/client/features/breakouts/types/rooms-breakouts-context';
import useAudio from '@/client/features/calls/hooks/use-audio';
import { useUpdateChannelsUserConnectionMutation } from '@/client/features/calls/operations/generated/operations.user';
import { fetcher as getCallChannelFetcher } from '@/client/features/calls/operations/get-call-channel';
import { joinBreakoutCall } from '@/client/features/calls/operations/join-breakout-call';
import { leaveChannelCall } from '@/client/features/calls/operations/leave-channel-call';
import { updateUserHandRaised } from '@/client/features/calls/operations/operations';
import { setPresence } from '@/client/features/calls/operations/presence';
import {
  recordExitVoiceChannelEvent,
  recordHandRaisedChannelEvent,
  recordJoinChannelEvents,
} from '@/client/features/calls/providers/call-provider/channels-events';
import { getAssociatedObjectUrl } from '@/client/features/channels/associated-objects';
import { useUserGetRoomKindQuery } from '@/client/features/rooms/generated/operations.user';
import {
  GetAllRoomRoomsSectionsDocument,
  type GetAllRoomRoomsSectionsQuery,
  type GetAllRoomRoomsSectionsQueryVariables,
  type RoomSectionDetailsFragment,
  useGetFullDefaultRoomBreakoutQuery,
  useGetFullRoomBreakoutQuery,
} from '@/client/features/rooms-sections/operations/generated/operations.user';
import { useToast } from '@/client/features/toast/providers/toast-provider';
import {
  GetRoomDetailDocument,
  type GetRoomDetailQuery,
  type GetRoomDetailQueryVariables,
  type RoomDetailFragment,
} from '@/client/pages/room/generated/get-room-detail.user';
import { useJoinBreakoutChannelMutation } from '@/client/pages/room/generated/join-room-channel.user';
import { Breakout_Type, Room_Kind, User_Role } from '@/generated/graphql/global-types.user';
import { useRouter } from '@/hooks/use-compatible-router';
import { useMediaDevices } from '@/hooks/use-media-devices';
import { useUser } from '@/hooks/use-user';
import { ROUTES, SFX_OPTIONS } from '@/legacy/lib/constants';
import type {
  CallProviderContext,
  CallProviderProps,
  CallTransition,
} from '@/providers/daily/call/call-provider.types';
import { useMediaDevicesContext as useMediaDeviceContext } from '@/providers/daily/media-devices/media-devices-provider';
import hasuraGraphQLClient, { wrapFetcher } from '@/shared/graphql/client';
import { setLocalStorageItem } from '@/shared/utils/browser';
import { useCallStore } from '@/stores/call/call-store';
import { CallError } from '@/stores/call/call-store.types';

const DAILY_URL = process.env.NEXT_PUBLIC_DAILY_DOMAIN;

if (!DAILY_URL) {
  throw new Error(`Missing NEXT_PUBLIC_DAILY_DOMAIN environment variable required for calls.`);
}

const getFullDefaultRoomBreakout = wrapFetcher(useGetFullDefaultRoomBreakoutQuery.fetcher);
const getFullRoomBreakout = wrapFetcher(useGetFullRoomBreakoutQuery.fetcher);
const getRoomKind = wrapFetcher(useUserGetRoomKindQuery.fetcher);

export const Context = createContext<CallProviderContext | null>(null);

export const CallProvider = ({ children }: CallProviderProps) => {
  const { mutateAsync: joinBreakoutChannel } = useJoinBreakoutChannelMutation(hasuraGraphQLClient.Client);
  const { mutateAsync: updateConnection } = useUpdateChannelsUserConnectionMutation(hasuraGraphQLClient.Client);

  const daily = useDaily();
  const { threshold } = useNetwork();
  const router = useRouter();
  const { user } = useUser();
  const { showSimpleToast } = useToast();

  const playChangeSfx = useAudio(changeSfx, SFX_OPTIONS);
  const playJoinSfx = useAudio(joinSfx, SFX_OPTIONS);
  const playLeaveSfx = useAudio(leaveSfx, SFX_OPTIONS);
  const playMuteSfx = useAudio(muteSfx, SFX_OPTIONS);
  const playUnmuteSfx = useAudio(unmuteSfx, SFX_OPTIONS);

  const { audioInputDevice, audioOutputDevice, videoInputDevice } = useMediaDevices();
  const { requestDevicePermissions } = useMediaDeviceContext();

  const hasJoinedInitially = useRef(false);

  const {
    currentRoom,
    currentRoomSection,
    currentBreakout,
    currentChannel,
    setCurrentRoom,
    setCurrentRoomSection,
    setCurrentBreakout,
    setCurrentChannel,
    setStatus,
    status,
    transitioning,
    setTransitioning,
    expectCalls,
    startMuted,
    pinned,
    setPinned,
    roomSections,
    setRoomSections,
    setStartMuted,
    sessions,
    setSessions,
    isCallForceMuted,
    isUserForceMuted,
    setIsCallForceMuted,
    setIsUserForceMuted,
    previewing,
    setPreviewing,
    initialAction,
  } = useCallStore((state) => state);

  const previousChannelId = usePrevious(currentChannel?.id);

  const ready = useMemo(() => {
    return !!daily;
  }, [daily]);

  const leaveCall = useCallback(
    async (redirect: boolean = true, redirectTo?: string) => {
      setStatus((prevState) => {
        return prevState === 'inactive' ? prevState : 'leaving';
      });
      console.debug('[call] Made call to leaveCall.', { status, currentRoom, currentRoomSection, currentBreakout });

      if (status === 'inactive' || status === 'leaving') {
        return;
      }
      const associatedObject = currentChannel?.associatedObject;

      if (user?.id && currentChannel) {
        void recordExitVoiceChannelEvent(currentChannel, user.id);
      }
      playLeaveSfx();

      setCurrentChannel(undefined);
      setCurrentBreakout(undefined);
      setCurrentRoom(undefined);
      setCurrentRoomSection(undefined);

      if (currentChannel) {
        await leaveChannelCall({ callObject: daily, channelId: currentChannel.id });
      } else {
        await daily?.leave();
      }
      if (redirect) {
        if (redirectTo) {
          await router.push(redirectTo);
        } else {
          if (router.pathname?.startsWith(ROUTES.room.prefix) && currentRoom) {
            await router.push(ROUTES.club.home({ clubUrlName: currentRoom.club.url_name }));
          } else if (router.pathname === ROUTES.call && associatedObject) {
            await router.push(getAssociatedObjectUrl(associatedObject));
          } else {
            router.reload();
          }
        }
      }
      setTransitioning(null);
      setPreviewing(false);
    },
    [
      setStatus,
      status,
      currentRoom,
      currentRoomSection,
      currentBreakout,
      currentChannel,
      user?.id,
      playLeaveSfx,
      setCurrentChannel,
      setCurrentBreakout,
      setCurrentRoom,
      setCurrentRoomSection,
      setTransitioning,
      setPreviewing,
      daily,
      router,
    ]
  );

  useEffect(() => {
    if (currentChannel?.id === previousChannelId) {
      return;
    }
    setIsCallForceMuted(currentChannel?.mute.enabled);
  }, [currentChannel?.id, currentChannel?.mute.enabled, previousChannelId, setIsCallForceMuted]);

  useEffect(() => {});

  const handleMeetingSessionStateUpdated = useCallback(
    (e: DailyEventObject) => {
      if (!currentChannel?.id) {
        return;
      }
      const key = `forceMuted:${currentChannel.id}`;
      const forceMuted = e.meetingSessionState.data[key];

      setIsCallForceMuted(Boolean(forceMuted));
    },
    [currentChannel?.id, setIsCallForceMuted]
  );
  useDailyEvent('meeting-session-state-updated', handleMeetingSessionStateUpdated);

  // TODO: Double check
  const handleLeftMeeting = useCallback(() => {
    void leaveCall(false);
  }, [leaveCall]);
  useDailyEvent('left-meeting', handleLeftMeeting);

  const joinCall = useCallback(
    async (
      callAccessToken: string | undefined,
      room: RoomDetailFragment,
      roomSection: RoomSectionDetailsFragment,
      breakout: Breakout,
      playSound: boolean = true
    ) => {
      const { channel_id: breakoutChannelId } = breakout;

      if (!breakoutChannelId) {
        throw new CallError('invalid-channel', 'Attempted to join a breakout without a channel id.');
      }
      if (status === 'joined-call' && currentBreakout?.channel_id === breakoutChannelId) {
        setStatus('joined-call');

        throw new CallError('same-call', 'Cannot join a call that you are already participating in.', false);
      }
      console.debug('[call] Made call to joinCall.', { status, currentRoom, currentRoomSection, currentBreakout });

      if (currentChannel) {
        await leaveChannelCall({ callObject: daily, channelId: breakoutChannelId });

        console.debug('[call] Already in a call so leaving it first.', {
          status,
          currentRoom,
          currentRoomSection,
          currentBreakout,
        });
      }
      setStatus('joining');
      setCurrentRoom(room);

      const channelPromise = getCallChannelFetcher(hasuraGraphQLClient.Client, { channelId: breakoutChannelId })();
      const roomKindResult = await getRoomKind({ roomId: room.id });
      const roomKind = roomKindResult?.rooms_by_pk?.kind ?? Room_Kind.Standard;
      const startVideo = roomKind === Room_Kind.Orientation; // Video should default to on when joining an orientation call
      const [joinedChannel] = await Promise.all([
        channelPromise,
        joinBreakoutCall({
          audioInputDeviceId: audioInputDevice?.deviceId,
          audioOutputDeviceId: audioOutputDevice?.deviceId,
          videoInputDeviceId: videoInputDevice?.deviceId,
          callAccessToken,
          callObject: daily,
          roomId: room.id,
          breakoutChannelId,
          startMuted,
          startAudio: true,
          startVideo,
        }),
      ]);

      if (!joinedChannel) {
        throw new CallError('join-fail');
      }
      if (playSound) {
        if (currentBreakout) {
          playChangeSfx();
        } else {
          playJoinSfx();
        }
      }
      setCurrentRoomSection(roomSection);
      setCurrentBreakout(breakout);
      setCurrentChannel(joinedChannel);

      console.debug('[call] Finished joinCall.', { status, currentRoom, currentRoomSection, currentBreakout });

      void recordJoinChannelEvents(currentChannel, joinedChannel, user?.id);
    },
    [
      status,
      currentBreakout,
      currentRoom,
      currentRoomSection,
      currentChannel,
      setStatus,
      setCurrentRoom,
      audioInputDevice?.deviceId,
      audioOutputDevice?.deviceId,
      videoInputDevice?.deviceId,
      daily,
      startMuted,
      setCurrentRoomSection,
      setCurrentBreakout,
      setCurrentChannel,
      user?.id,
      playChangeSfx,
      playJoinSfx,
    ]
  );

  const joinRoom = useCallback(
    async (
      room: RoomDetailFragment,
      roomSection: RoomSectionDetailsFragment,
      breakout: Breakout,
      playSound: boolean = false,
      force: boolean = false
    ) => {
      if (!force && breakout.type === Breakout_Type.Private && user?.role !== User_Role.Team) {
        console.debug('Non-team user tried to join a private breakout of their own accord and was denied.');

        throw new CallError('unauthorized', 'Attempted to manually join a private breakout.');
      }
      if (currentChannel) {
        setStatus('leaving');
      }
      console.debug('[call] Made call to joinRoom.', { status, currentRoom, currentRoomSection, currentBreakout });

      const {
        joinBreakout: { callAccessToken },
      } = await joinBreakoutChannel({
        roomId: room.id,
        breakoutId: breakout.id,
        clubId: room.club.id,
        joinCall: true,
        activeStatus: true,
        startMuted,
      });

      if (!callAccessToken) {
        // A call to "joinCall" will attempt to generate a new token but failing early is fine
        throw new Error('Failed to generate valid call access token.');
      }
      await joinCall(callAccessToken, room, roomSection, breakout, playSound);
    },
    [
      user?.role,
      currentChannel,
      status,
      currentRoom,
      currentRoomSection,
      currentBreakout,
      joinBreakoutChannel,
      startMuted,
      joinCall,
      setStatus,
    ]
  );

  const joinBreakout = useCallback(
    async (
      room: RoomDetailFragment,
      roomSection: RoomSectionDetailsFragment,
      breakout: Breakout,
      playSound: boolean = false,
      force: boolean = false
    ) => {
      console.debug('[call] Made call to joinBreakout.', {
        status,
        currentRoom,
        currentRoomSection,
        currentBreakout,
        force,
      });

      if (!force && breakout.type === Breakout_Type.Private && user?.role !== User_Role.Team) {
        console.debug('Non-team user tried to join a private breakout of their own accord and was denied.');

        throw new CallError('unauthorized', 'Attempted to manually join a private breakout.');
      }
      if (!currentRoom?.id || currentRoom.id !== room.id) {
        console.debug('[call] Target breakout is not in the same room, calling joinRoom instead.', {
          status,
          currentRoom,
          currentRoomSection,
          currentBreakout,
        });
        await joinRoom(room, roomSection, breakout, playSound);

        return;
      }
      const { channel_id: breakoutChannelId } = breakout;

      if (!breakoutChannelId) {
        throw new CallError('invalid-channel', 'Attempted to join a breakout without a channel id.');
      }
      setStatus('joining');
      setCurrentBreakout(breakout);

      const {
        joinBreakout: { channelId },
      } = await joinBreakoutChannel({
        roomId: room.id,
        breakoutId: breakout.id,
        clubId: room.club.id,
        joinCall: false,
        activeStatus: true,
        startMuted,
      });

      if (!channelId) {
        throw new CallError('join-fail');
      }
      if (playSound) {
        if (currentChannel) {
          playChangeSfx();
        } else {
          playJoinSfx();
        }
      }
      const presenceUpdated = await setPresence({
        channelId: breakoutChannelId,
      });

      if (!presenceUpdated) {
        throw new CallError('presence-fail');
      }
      const joinedChannel = await getCallChannelFetcher(hasuraGraphQLClient.Client, { channelId: breakoutChannelId })();

      if (!joinedChannel) {
        throw new CallError('invalid-channel', 'Breakout did not have a paired channel.');
      }
      setCurrentRoom(room);
      setCurrentRoomSection(roomSection);
      setCurrentBreakout(breakout);
      setCurrentChannel(joinedChannel);

      void recordJoinChannelEvents(currentChannel, joinedChannel, user?.id);
    },
    [
      status,
      currentRoom,
      currentRoomSection,
      currentBreakout,
      user?.role,
      user?.id,
      setStatus,
      setCurrentBreakout,
      joinBreakoutChannel,
      startMuted,
      setCurrentRoom,
      setCurrentRoomSection,
      setCurrentChannel,
      currentChannel,
      joinRoom,
      playChangeSfx,
      playJoinSfx,
    ]
  );

  const _transition = useCallback(
    async (transition: CallTransition) => {
      if (status === 'joining' || status === 'leaving') {
        throw new CallError('already-transitioning', 'Already joining a room/breakout', false);
      }
      const room = 'room' in transition ? transition.room : currentRoom;
      const roomId = room?.id;

      if (!roomId) {
        throw new CallError('malformed-room');
      }
      const { rooms_by_pk: breakoutRoom } = transition.breakout?.id
        ? await getFullRoomBreakout({ roomId, breakoutId: transition.breakout.id })
        : await getFullDefaultRoomBreakout({ roomId });
      const roomSection = breakoutRoom?.rooms_sections?.[0];

      if (!roomSection) {
        throw new CallError('malformed-room-section');
      }
      const breakout = roomSection?.breakouts?.[0];

      if (!breakout) {
        throw new CallError('malformed-breakout');
      }
      if (status === 'inactive') {
        await joinCall(undefined, room, roomSection, breakout);

        return;
      }
      switch (transition.type) {
        case 'join-room':
          await joinRoom(room, roomSection, breakout, true);

          break;
        case 'join-breakout':
          await joinBreakout(room, roomSection, breakout, true, transition.force);

          break;
      }
    },
    [status, currentRoom, joinBreakout, joinCall, joinRoom]
  );

  const transition = useCallback(
    async (transition: CallTransition) => {
      if (expectCalls !== true) {
        throw new CallError('page-not-expecting-calls');
      }
      try {
        switch (transition.type) {
          case 'join-breakout':
            setTransitioning('breakout');

            break;
          case 'join-room':
            setTransitioning('room');

            break;
        }
        await _transition(transition);

        console.debug('[call] Finished transition.', { status, currentRoom, currentRoomSection, currentBreakout });

        setStatus('joined-call');
        setTransitioning(null);
      } catch (err: unknown) {
        // If there's been an issue joining a breakout it'd be difficult and possibly unnatural to undo it and send
        // the user back to the call they were in. I think it's preferable to just remove them from the call.
        if ((err instanceof CallError && err.leaveCall) || !(err instanceof CallError)) {
          console.error('Error attempting to make a call transition.', err);

          await leaveCall();

          showSimpleToast({
            title: 'Issue joining breakout',
            description: `${err instanceof CallError ? `[${err.errorType}] ${err.message ?? 'There was an issue joining the call. '}` : ''}Please contact support if this continues to happen.`,
            color: 'danger',
          });
        } else {
          console.warn('Error attempting to make a call transition.', err);

          // It's the responsibility of wherever this error is thrown to correct the call status
          setTransitioning(null);
        }
      }
    },
    [
      expectCalls,
      _transition,
      status,
      currentRoom,
      currentRoomSection,
      currentBreakout,
      setStatus,
      setTransitioning,
      leaveCall,
      showSimpleToast,
    ]
  );

  const leaveCallOnUnmount = useStableCallback(() => {
    void leaveCall();
  });

  const enterPreviewMode = useCallback(
    async (room: string, previewType: 'orientation' | 'regular') => {
      if (!daily || !user?.id) {
        return;
      }
      setTransitioning('preview');
      setStatus('joining');

      try {
        let tokenData;

        try {
          tokenData = await fetch(`/api/calls/generate?roomId=${room}`).then((res) => res.json());

          if (!tokenData?.success) {
            return;
          }
        } catch (err) {
          return;
        }
        const options: DailyCallOptions = {
          url: `${DAILY_URL}${room}`,
          token: tokenData.token,
          startVideoOff: false,
          startAudioOff: false,
        };

        await daily.preAuth(options);
        await daily.startCamera(options);

        daily.setLocalVideo(true);
        daily.setLocalAudio(true);

        setPreviewing(previewType);
        setStatus('joined-preview');
      } catch (err) {
        console.error('error joining preview', err);
        await leaveCall();
      } finally {
        setTransitioning(null);
      }
    },
    [daily, leaveCall, setPreviewing, setStatus, setTransitioning, user?.id]
  );

  const exitPreviewMode = useCallback(async () => {
    if (!previewing) {
      return;
    }
    setPreviewing(false);

    daily?.setLocalVideo(false);
    daily?.setLocalAudio(false);

    await daily?.leave();
  }, [daily, previewing, setPreviewing]);

  useEffect(() => {
    return leaveCallOnUnmount;
  }, [leaveCallOnUnmount]);

  useEffect(() => {
    if (!user?.id || !currentChannel?.id) {
      return;
    }
    void updateConnection({ channelId: currentChannel.id, userId: user.id, connection: threshold });
  }, [threshold, user?.id, currentChannel?.id, updateConnection]);

  useGraphQLSubscription<GetAllRoomRoomsSectionsQuery, GetAllRoomRoomsSectionsQueryVariables>({
    ...(currentRoom ? { variables: { roomId: currentRoom.id }, enabled: true } : { enabled: false }),
    query: GetAllRoomRoomsSectionsDocument,
    useRealtimeSubscription: true,
    onNext: (data: GetAllRoomRoomsSectionsQuery) => {
      if (data.rooms_sections) {
        setRoomSections(data.rooms_sections as RoomSectionDetailsFragment[]);
      }
    },
    onError: console.error,
  });

  useGraphQLSubscription<GetRoomDetailQuery, GetRoomDetailQueryVariables>({
    ...(status === 'joined-call' && user?.id && currentRoom
      ? { enabled: true, variables: { roomId: currentRoom.id, userId: user.id } }
      : { enabled: false }),
    query: GetRoomDetailDocument,
    onNext: (data) => {
      const newRoom = data.rooms_by_pk;

      if (!newRoom) {
        return;
      }
      setCurrentRoom((prevState) => {
        if (prevState?.id !== newRoom?.id) {
          return prevState;
        }
        return newRoom;
      });
    },
    onError: console.error,
  });

  const updateHandRaised = useCallback(
    async (channelId: string | undefined, userId: string, handRaised: boolean): Promise<void> => {
      if (!channelId || !userId) {
        return;
      }
      await updateUserHandRaised(channelId, userId, handRaised);

      if (handRaised) {
        void recordHandRaisedChannelEvent(channelId, userId);
      }
    },
    []
  );

  const updateMute = useCallback(
    async (muted: boolean, playSound: boolean = true): Promise<void> => {
      if (!daily) {
        return;
      }
      if ((isUserForceMuted || isCallForceMuted) && !muted && user?.role !== User_Role.Team) {
        console.debug('isUserForceMuted', isUserForceMuted);
        console.debug('isCallForceMuted', isCallForceMuted);
        return;
      }
      daily.setLocalAudio(!muted);
      setStartMuted(muted);

      setLocalStorageItem('microphone_muted', String(muted));

      if (playSound) {
        if (muted) {
          playMuteSfx();
        } else {
          playUnmuteSfx();
        }
      }
    },
    [daily, isCallForceMuted, isUserForceMuted, playMuteSfx, playUnmuteSfx, setStartMuted, user?.role]
  );

  const startScreenSharing = useCallback(() => {
    daily?.startScreenShare();
  }, [daily]);

  const stopScreenSharing = useCallback(() => {
    daily?.stopScreenShare();
  }, [daily]);

  const startWebCam = useCallback(() => {
    void daily?.setLocalVideo(true);
  }, [daily]);

  const stopWebCam = useCallback(() => {
    void daily?.setLocalVideo(false);
  }, [daily]);

  useIntervalWhen(
    () => {
      if (currentChannel?.id && user) {
        void setPresence({
          channelId: currentChannel.id,
        });
      }
    },
    3000,
    !!(currentChannel?.id && user)
  );

  useEffect(() => {
    if (status !== 'inactive' || !ready) {
      return;
    }
    if (initialAction?.type === 'join-call' && !currentRoom) {
      return;
    }
    if (hasJoinedInitially.current) {
      return;
    }
    const init = async () => {
      // const muted = getLocalStorageItem('microphone_muted', 'false') === 'true';
      hasJoinedInitially.current = true;

      if (initialAction?.type) {
        await requestDevicePermissions({ audio: true, video: true, combined: false });
      }
      if (initialAction?.type === 'join-call') {
        void transition({ type: 'join-room', room: currentRoom!, breakout: currentBreakout }).then(() => {
          daily?.setLocalAudio(true); // I think some kids are getting confused by this so enabling by default
          daily?.setLocalVideo(currentRoom?.kind === 'orientation');
        });
      } else if (initialAction?.type === 'enter-preview') {
        console.debug('enter preview', initialAction);
        void enterPreviewMode(initialAction.previewRoomId, 'regular');
      }
    };

    void init();
  }, [
    currentBreakout,
    currentRoom,
    daily,
    enterPreviewMode,
    initialAction,
    ready,
    requestDevicePermissions,
    status,
    transition,
  ]);

  useEffect(() => {
    if (user?.role === User_Role.Team) {
      return;
    }
    if (!daily || !isUserForceMuted) {
      return;
    }
    daily.setLocalAudio(false);
  }, [daily, isUserForceMuted, user?.role]);

  useEffect(() => {
    if (user?.role === User_Role.Team) {
      return;
    }
    if (!daily || !isCallForceMuted) {
      return;
    }
    daily.setLocalAudio(false);
  }, [daily, isCallForceMuted, user?.role]);

  const context: CallProviderContext = useMemo(
    () => ({
      previewing,
      expectCalls,
      currentRoom,
      currentRoomSection,
      currentBreakout,
      currentChannel,
      status,
      transition,
      leaveCall,
      setCurrentRoomSection,
      ready,
      roomSections,
      pinned,
      setPinned,
      startMuted,
      sessions,
      setSessions,
      isCallForceMuted,
      isUserForceMuted,
      setIsCallForceMuted,
      setIsUserForceMuted,
      updateMute,
      updateHandRaised,
      startScreenSharing,
      stopScreenSharing,
      startWebCam,
      stopWebCam,
      enterPreviewMode,
      exitPreviewMode,
      transitioning,
      initialAction,
    }),
    [
      previewing,
      expectCalls,
      currentRoom,
      currentRoomSection,
      currentBreakout,
      currentChannel,
      status,
      transition,
      leaveCall,
      setCurrentRoomSection,
      ready,
      roomSections,
      pinned,
      setPinned,
      startMuted,
      sessions,
      setSessions,
      isCallForceMuted,
      isUserForceMuted,
      setIsCallForceMuted,
      setIsUserForceMuted,
      updateMute,
      updateHandRaised,
      startScreenSharing,
      stopScreenSharing,
      startWebCam,
      stopWebCam,
      enterPreviewMode,
      exitPreviewMode,
      transitioning,
      initialAction,
    ]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useNullableCall = () => {
  return useContext(Context) ?? undefined;
};

/**
 * A hook that provides access to the CallProviderContext.
 *
 * If attempting to access call store variables rather than computed values from
 * the CallProviderContext, you might want to just use the useCallStore hook
 * instead as it provides more flexible access to these fields.
 */
export const useCall = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useCall must be used within a CallProvider');
  }
  return context;
};
