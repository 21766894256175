import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import Close from '@/client/design-system/icons/close.svg';
import { FeedbackForm } from '@/client/features/feedback/components/feedback-form';

export interface Props {
  associatedObject?: string;
  badQuestion?: string;
  goodQuestion?: null | string;
  neutralQuestion?: null | string;
  onClose: () => void;
  open: boolean;
  title: string;
}

export const FeedbackModal = (props: Props) => {
  const { associatedObject, badQuestion, goodQuestion, neutralQuestion, onClose, open, title } = props;

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/80 backdrop-blur-[5px]' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='relative w-full max-w-screen-xs overflow-hidden rounded-sm border-1 border-border bg-dark-grey-2 pt-9 align-middle shadow-xl transition-all'>
                  <Close
                    aria-label='Close'
                    className='absolute right-3 top-3 size-6 cursor-pointer text-light-grey-3 hover:text-light-grey-2 active:text-white'
                    onClick={onClose}
                    role='button'
                  />
                  <Dialog.Title className='px-8 text-title3 font-bold text-white'>{title}</Dialog.Title>
                  <FeedbackForm
                    associatedObject={associatedObject}
                    badQuestion={badQuestion}
                    goodQuestion={goodQuestion}
                    neutralQuestion={neutralQuestion}
                    onCancel={onClose}
                    onSubmit={onClose}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
