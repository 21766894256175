import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import { type ReactNode } from 'react';

import { Transitions } from '@/client/design-system/animations/framer-motion/transitions';
import { Alert } from '@/client/features/alert/components/alert';

interface AlertContainerProps {
  alerts: Record<string, { content: ReactNode; onclose: () => void; sticky?: boolean }>;
  onClose: (identifier: string) => void;
  className?: string;
}

export const AlertContainer = ({ alerts, onClose, className }: AlertContainerProps) => {
  const keys = Object.keys(alerts);
  const size = keys.length;

  return (
    <div className={className}>
      {keys.slice(-2).map((identifier, index) => {
        const alert = alerts[identifier]?.content;
        const isBottomAlert = size > 1 && index === 0;
        const isTopAlert = (size > 1 && index === 1) || size === 1;

        return (
          <motion.div
            key={identifier}
            initial={{ scaleX: isTopAlert ? 1.05 : 1, filter: 'brightness(1.0)' }}
            animate={{ scaleX: isTopAlert ? 1 : 0.95, filter: isBottomAlert ? 'brightness(0.75)' : 'brightness(1.0)' }}
            exit={{ scaleX: isTopAlert ? 0.95 : 0.9, filter: 'brightness(0.5)' }}
            transition={Transitions.Ease}
            className={clsx(
              'mx-auto w-full rounded-xl',
              size > 1 && index === 1 ? '-mt-10' : 'mt-0',
              isTopAlert && 'shadow-[0_20px_32px_-12px_rgba(0,0,0,0.80)]'
            )}
          >
            <Alert
              onClose={() => {
                onClose(identifier);
              }}
              sticky={alerts[identifier]?.sticky}
            >
              {alert}
            </Alert>
          </motion.div>
        );
      })}
    </div>
  );
};
