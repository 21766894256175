export interface CompetitionsPrize {
  money: number;
  name: string;
  place: number;
  xp?: number;
}

export enum CompetitionsPrizeType {
  Team = 'team',
  Participant = 'participant',
}

export interface CompetitionsPrizeMeta {
  preset_prizes: CompetitionsPrize[];
  type: CompetitionsPrizeType;
}
