import { useMemo } from 'react';
import { type IntercomProps, useIntercom as useReactUseIntercom } from 'react-use-intercom';

let enabledIndex = 0;

export const useIntercom = () => {
  const { boot, showNewMessage, startTour, update } = useReactUseIntercom();

  return useMemo(() => {
    const enableIntercom = (intercomProps?: IntercomProps) => {
      const myIndex = enabledIndex++;

      if (myIndex === 0) {
        boot(intercomProps);
      } else {
        update({
          hideDefaultLauncher: intercomProps?.hideDefaultLauncher ?? false,
        });
      }

      return () => {
        if (myIndex === enabledIndex) {
          update({
            hideDefaultLauncher: intercomProps?.hideDefaultLauncher ?? true,
          });
        }
      };
    };

    return {
      enableIntercom,
      openIntercomMessenger: showNewMessage,
      startTour: (tourId: number, intercomProps?: IntercomProps) => {
        enableIntercom(intercomProps);
        startTour(tourId);
      },
    };
  }, [boot, showNewMessage, startTour, update]);
};
