import { useInsertChannelEventMutation } from '@/client/features/channels/operations/generated/create-channels-event.user';
import { wrapFetcher } from '@/shared/graphql/client';
import type { ChannelEvent } from '@/shared/graphql/schema-extensions/scalars/channels-events';

const insertChannelEvent = wrapFetcher(useInsertChannelEventMutation.fetcher);

export const createChannelEvent = async (channelId: string, channelEvent: ChannelEvent): Promise<void> => {
  try {
    await insertChannelEvent({ channelId, channelEvent });
  } catch (error: unknown) {
    console.error(error);
  }
};
