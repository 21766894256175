import { createStitches } from '@stitches/react';

import { scrollbar, scrollbarMarginBlock } from '../utils';

export const { styled, css, globalCss, keyframes, theme, getCssText } = createStitches({
  utils: {
    scrollbar,
    scrollbarMarginBlock,
  },
});
