interface PageViewOptions {
  trackingId: string;
  url: string;
}

export const pageView = ({ trackingId, url }: PageViewOptions): void => {
  if (!window.gtag) {
    console.warn('Google Tag Manager not available in window.');
    return;
  }

  window.gtag('config', trackingId, {
    page_location: url,
  });
};

interface EventOptions {
  action: Gtag.EventNames;
  category?: string;
  label?: string;
  value?: number;
}

export const event = ({ action, category, label, value }: EventOptions): void => {
  if (!window.gtag) {
    console.warn('Google Tag Manager not available in window.');
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};
