export enum FeedType {
  competitionProjectSubmitted = 'competitions:participants',
  competitionStartsSoon = 'competitions:starts-soon',
  competitionsWinners = 'competitions:winners',
  ordersInsert = 'orders:insert',
  postsInsert = 'posts:insert',
  projectStandaloneSubmitted = 'projects:standalone:insert',
  /** @deprecated Don't use this. Ever. I will hunt you down. */
  seasonFinaleWinner = 'season-finale:winner',
  themesActive = 'themes:active',
}
