interface FloatingLabelProps {
  label: string;
  focus: boolean;
  hasError: boolean;
  small?: boolean;
  getInputTarget?: (labelElement: HTMLLabelElement) => HTMLInputElement;
}

export const FloatingLabel = ({ label, focus, hasError, small = false, getInputTarget }: FloatingLabelProps) => {
  const labelColor = focus && !hasError ? 'text-accent' : hasError ? 'text-accent-red' : 'text-light-grey-2';

  return (
    <label
      className={`absolute cursor-text pl-4 transition-all duration-150 ${
        small ? 'top-2 text-caption1' : 'top-3.6 text-headline2'
      } ${labelColor}`}
      onClick={(e) => {
        const target = getInputTarget?.(e.currentTarget) ?? (e.currentTarget.nextSibling as HTMLInputElement);
        target.focus();
      }}
    >
      {label}
    </label>
  );
};
