import { useEffect, useState } from 'react';

import { choosePlanQuerySchema, ChoosePlanResult } from '@/client/features/subscriptions/choose-plan-api';
import { useToast } from '@/client/features/toast/providers/toast-provider';
import { useRouter } from '@/hooks/use-compatible-router';
import { safeExhaustiveCheckFallback } from '@/shared/utils/correctness';

export const ChoosePlanToasts = () => {
  const router = useRouter();
  const { showSimpleToast } = useToast();

  const [result, setResult] = useState<null | ChoosePlanResult>(null);
  const [reason, setReason] = useState<null | string>(null);

  useEffect(() => {
    const parsedParams = choosePlanQuerySchema.safeParse(router.query);

    if (parsedParams.success) {
      setResult(parsedParams.data.plan_result);
      setReason(parsedParams.data.plan_reason || null);
    } else {
      setResult(null);
      setReason(null);
    }
  }, [router.query]);

  useEffect(() => {
    if (result === null) {
      return;
    }

    if (result === ChoosePlanResult.Failed) {
      showSimpleToast({
        color: 'danger',
        title: 'Plan selection failed',
        description: reason,
      });
    } else if (result === ChoosePlanResult.Confirmed || result === ChoosePlanResult.Updated) {
      showSimpleToast({
        color: 'success',
        title: 'Thanks for confirming your plan!',
      });
    } else {
      console.warn(safeExhaustiveCheckFallback(result, 'Unexpected plan selection result'));
    }
  }, [reason, result, showSimpleToast]);

  return null;
};
