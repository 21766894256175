import type * as Types from '@/generated/graphql/global-types.user';

export type ChannelAssociatedObjectsFragment = (
  { readonly __typename?: 'channels' }
  & { readonly breakout?: Types.Maybe<(
    { readonly __typename?: 'breakouts' }
    & Pick<Types.Breakouts, 'association_id' | 'id' | 'name' | 'associated_object' | 'type'>
    & { readonly room_section?: Types.Maybe<(
      { readonly __typename?: 'rooms_sections' }
      & { readonly room: (
        { readonly __typename?: 'rooms' }
        & Pick<Types.Rooms, 'kind'>
      ) }
    )> }
  )>, readonly project?: Types.Maybe<(
    { readonly __typename?: 'projects' }
    & Pick<Types.Projects, 'association_id' | 'id' | 'name'>
  )>, readonly room?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'association_id' | 'feedback_question' | 'id' | 'kind' | 'name' | 'promo_image_url' | 'status' | 'url_name'>
    & { readonly club: (
      { readonly __typename?: 'clubs' }
      & Pick<Types.Clubs, 'url_name'>
    ) }
  )>, readonly rooms_sections?: Types.Maybe<(
    { readonly __typename?: 'rooms_sections' }
    & Pick<Types.Rooms_Sections, 'id' | 'name' | 'image_url' | 'icon_image_url' | 'sort_order' | 'association_id' | 'room_id' | 'color' | 'type' | 'platform_settings' | 'created_at'>
    & { readonly breakouts: ReadonlyArray<(
      { readonly __typename?: 'breakouts' }
      & Pick<Types.Breakouts, 'id' | 'name' | 'type'>
      & { readonly room_section?: Types.Maybe<(
        { readonly __typename?: 'rooms_sections' }
        & Pick<Types.Rooms_Sections, 'room_id'>
      )>, readonly channel?: Types.Maybe<(
        { readonly __typename?: 'channels' }
        & Pick<Types.Channels, 'id'>
      )> }
    )> }
  )> }
);


export const ChannelAssociatedObjectsFragmentDoc = `
    fragment ChannelAssociatedObjects on channels {
  breakout {
    association_id
    id
    name
    associated_object
    type
    room_section {
      room {
        kind
      }
    }
  }
  project {
    association_id
    id
    name
  }
  room {
    association_id
    feedback_question
    id
    kind
    name
    promo_image_url
    status
    url_name
    club {
      url_name
    }
  }
  rooms_sections: room_section {
    id
    name
    image_url
    icon_image_url
    sort_order
    association_id
    room_id
    color
    type
    platform_settings
    created_at
    breakouts {
      id
      name
      type
      room_section {
        room_id
      }
      channel {
        id
      }
    }
  }
}
    `;