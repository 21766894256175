import type { AnalyticsBrowser } from '@segment/analytics-next';
import { snake, title } from 'case';

import type {
  AnalyticsService,
  AnonymousIdCallback,
  PageViewCallback,
  TrackingCallback,
  UserAliasCallback,
  UserIdentificationCallback,
  UserResetCallback,
} from '@/client/features/analytics/service';

type SegmentAdapter = Awaited<AnalyticsBrowser>[0];

const withSuppressedError = <A extends unknown[], T>(
  promiseLikeSupplier: (...args: A) => T | Promise<T>
): ((...args: A) => Promise<T | undefined>) => {
  return async (...args) => {
    try {
      return await promiseLikeSupplier(...args);
    } catch {
      return undefined;
    }
  };
};

declare const window: Window &
  typeof globalThis & {
    refiner: any;
  };

export const fromSegmentAdapter = (adapter: SegmentAdapter): AnalyticsService => {
  const anonymousId: AnonymousIdCallback = withSuppressedError(async () => adapter.setAnonymousId());

  const identifyUser: UserIdentificationCallback = withSuppressedError(async (options, context) => {
    const { userId, properties } = options;

    const formattedProperties = formatProperties(properties, ArraySerialization.FlattenPad);

    await adapter.identify(userId, formattedProperties, context);

    if (typeof window !== 'undefined' && userId) {
      window.refiner('identifyUser', {
        id: userId,
        ...properties,
      });
    }
  });

  const resetUser: UserResetCallback = withSuppressedError(async () => {
    adapter.reset();
  });

  const trackPageView: PageViewCallback = withSuppressedError(async (options, context) => {
    const { page, properties } = options;

    const formattedPageName = formatPageName(page);
    const formattedProperties = formatProperties(properties);

    await adapter.page(formattedPageName, formattedProperties, context);
  });

  const aliasUser: UserAliasCallback = async (anonymousId: string, userId: string) => {
    await adapter.alias(anonymousId, userId);
  };

  const trackEvent: TrackingCallback = withSuppressedError(async (options, context) => {
    const { object, action, source, properties } = options;

    const formattedEventName = formatEventName(object, action);
    const formattedSource = formatEventSource(source);
    const formattedProperties = formatProperties(properties);
    const mergedProperties = formattedSource
      ? { ...formattedProperties, platform: 'web', source: formattedSource }
      : { ...formattedProperties, platform: 'web' };
    await adapter.track(formattedEventName, mergedProperties, context);
  });

  return { anonymousId, identifyUser, resetUser, trackPageView, aliasUser, trackEvent };
};

export const formatPageName = (page: string): string => title(page);

export const formatEventName = (object: string, action: string): string => `${title(object)} ${title(action)}`;

export const formatEventSource = (source: unknown | undefined): string | undefined =>
  typeof source === 'string' ? title(source) : undefined;

export enum ArraySerialization {
  Raw, // serialize a single property with an array value
  Flatten, // serialize into a series of properties like `${name}_${index + 1}`
  FlattenPad, // flatten and include an additional null value beyond the arrays length i.e. will handle removal from an array as long as only one item is removed at a time.
}

export const formatProperties = (
  properties: unknown,
  arraySerialization: ArraySerialization = ArraySerialization.Raw
): object | undefined => {
  if (!properties) return undefined;

  const entries: Array<[string, any]> = [];

  for (const [propertyName, propertyValue] of Object.entries(properties)) {
    if (Array.isArray(propertyValue)) {
      if (arraySerialization !== ArraySerialization.Raw) {
        const values = arraySerialization === ArraySerialization.FlattenPad ? [...propertyValue, null] : propertyValue;

        values.forEach((singleValue, index) => {
          entries.push([
            `${snake(propertyName)}_${index + 1}`,
            singleValue !== null && typeof propertyValue === 'object' ? formatProperties(singleValue) : singleValue,
          ]);
        });
      } else {
        entries.push([
          snake(propertyName),
          propertyValue.map((singleValue) =>
            propertyValue !== null && typeof propertyValue === 'object' ? formatProperties(singleValue) : singleValue
          ),
        ]);
      }
    } else if (propertyValue !== null && typeof propertyValue === 'object') {
      if (propertyValue instanceof Date) {
        entries.push([snake(propertyName), propertyValue.toISOString()]);
      } else {
        entries.push([snake(propertyName), formatProperties(propertyValue)]);
      }
    } else {
      entries.push([snake(propertyName), propertyValue]);
    }
  }

  return Object.fromEntries(entries);
};
