import { Howl } from 'howler';
import { useCallback, useEffect, useRef } from 'react';

import type { IAudioOptions } from '../types/audio-options';
import useInteraction from './use-interaction';

export default function useAudio(soundPath: string, options?: IAudioOptions | undefined): () => number | undefined {
  const hasInteracted = useInteraction();
  const audioRef = useRef<Howl>();

  useEffect(() => {
    if (!hasInteracted) {
      return;
    }

    const audio = new Howl({ src: soundPath, ...options });
    audioRef.current = audio;

    return () => {
      audio.unload();
    };
  }, [hasInteracted, options, soundPath]);

  return useCallback(() => audioRef.current?.play(), []);
}
