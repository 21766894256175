'use client';

import { H } from '@highlight-run/next/client';
import { useEffect } from 'react';

export function CustomHighlightStart() {
  useEffect(() => {
    const shouldStartHighlight = window.location.hostname !== 'localhost'; // Not sure if this works

    if (!shouldStartHighlight) {
      return;
    }
    H.start();

    return () => {
      H.stop();
    };
  });

  return null;
}
