import type { DialogContentProps } from '@radix-ui/react-dialog';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';

import { Transitions } from '../../animations/framer-motion/transitions';
import { Icon } from '../icon';
import { StyledClose, StyledContent, StyledOverlay } from './modal.styles';

interface ModalProps extends DialogContentProps {
  closeable?: boolean;
  overlay?: 'default' | 'black';
}

const overlayAnimationVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const contentAnimationVariants = {
  hidden: {
    opacity: 0,
    transform: 'translate(-50%, -45%)',
  },
  visible: {
    opacity: 1,
    transform: 'translate(-50%, -50%)',
  },
};

export const ModalContent = forwardRef<HTMLDivElement, ModalProps>(
  ({ children, closeable = true, overlay = 'default', ...props }, forwardedRef) => (
    <DialogPrimitive.Portal>
      <motion.div
        variants={overlayAnimationVariants}
        initial='hidden'
        animate='visible'
        transition={Transitions.Spring}
      >
        <StyledOverlay key='modalOverlay' color={overlay} />
      </motion.div>
      <StyledContent key='modalContent' {...props} ref={forwardedRef} asChild>
        <motion.div
          variants={contentAnimationVariants}
          initial='hidden'
          animate='visible'
          transition={Transitions.Spring}
        >
          {closeable && (
            <StyledClose>
              <Icon icon='close' />
            </StyledClose>
          )}
          {children}
        </motion.div>
      </StyledContent>
    </DialogPrimitive.Portal>
  )
);

export const Modal = DialogPrimitive.Root;
export const ModalTrigger = DialogPrimitive.Trigger;
export const ModalClose = DialogPrimitive.Close;

export const ModalOverlay = StyledOverlay;
