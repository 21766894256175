import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type CompetitionNameForActivityQueryVariables = Types.Exact<{
  competitionId: Types.Scalars['String']['input'];
}>;


export type CompetitionNameForActivityQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly competitions_by_pk?: Types.Maybe<(
    { readonly __typename?: 'competitions' }
    & Pick<Types.Competitions, 'name'>
  )> }
);

export type UserCreationDateForActivityQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;


export type UserCreationDateForActivityQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly users_by_pk?: Types.Maybe<(
    { readonly __typename?: 'users' }
    & Pick<Types.Users, 'created_at'>
  )> }
);



export const CompetitionNameForActivityDocument = `
    query CompetitionNameForActivity($competitionId: String!) {
  competitions_by_pk(id: $competitionId) {
    name
  }
}
    `;

export const useCompetitionNameForActivityQuery = <
      TData = CompetitionNameForActivityQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: CompetitionNameForActivityQueryVariables,
      options?: UseQueryOptions<CompetitionNameForActivityQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<CompetitionNameForActivityQuery, TError, TData>(
      ['CompetitionNameForActivity', variables],
      fetcher<CompetitionNameForActivityQuery, CompetitionNameForActivityQueryVariables>(client, CompetitionNameForActivityDocument, variables, headers),
      options
    )};

useCompetitionNameForActivityQuery.getKey = (variables: CompetitionNameForActivityQueryVariables) => ['CompetitionNameForActivity', variables];

export const useInfiniteCompetitionNameForActivityQuery = <
      TData = CompetitionNameForActivityQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: CompetitionNameForActivityQueryVariables,
      options?: UseInfiniteQueryOptions<CompetitionNameForActivityQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<CompetitionNameForActivityQuery, TError, TData>(
      ['CompetitionNameForActivity.infinite', variables],
      (metaData) => fetcher<CompetitionNameForActivityQuery, CompetitionNameForActivityQueryVariables>(client, CompetitionNameForActivityDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteCompetitionNameForActivityQuery.getKey = (variables: CompetitionNameForActivityQueryVariables) => ['CompetitionNameForActivity.infinite', variables];


useCompetitionNameForActivityQuery.fetcher = (client: GraphQLClient, variables: CompetitionNameForActivityQueryVariables, headers?: RequestInit['headers']) => fetcher<CompetitionNameForActivityQuery, CompetitionNameForActivityQueryVariables>(client, CompetitionNameForActivityDocument, variables, headers);

export const UserCreationDateForActivityDocument = `
    query UserCreationDateForActivity($userId: String!) {
  users_by_pk(id: $userId) {
    created_at
  }
}
    `;

export const useUserCreationDateForActivityQuery = <
      TData = UserCreationDateForActivityQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: UserCreationDateForActivityQueryVariables,
      options?: UseQueryOptions<UserCreationDateForActivityQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<UserCreationDateForActivityQuery, TError, TData>(
      ['UserCreationDateForActivity', variables],
      fetcher<UserCreationDateForActivityQuery, UserCreationDateForActivityQueryVariables>(client, UserCreationDateForActivityDocument, variables, headers),
      options
    )};

useUserCreationDateForActivityQuery.getKey = (variables: UserCreationDateForActivityQueryVariables) => ['UserCreationDateForActivity', variables];

export const useInfiniteUserCreationDateForActivityQuery = <
      TData = UserCreationDateForActivityQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: UserCreationDateForActivityQueryVariables,
      options?: UseInfiniteQueryOptions<UserCreationDateForActivityQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<UserCreationDateForActivityQuery, TError, TData>(
      ['UserCreationDateForActivity.infinite', variables],
      (metaData) => fetcher<UserCreationDateForActivityQuery, UserCreationDateForActivityQueryVariables>(client, UserCreationDateForActivityDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteUserCreationDateForActivityQuery.getKey = (variables: UserCreationDateForActivityQueryVariables) => ['UserCreationDateForActivity.infinite', variables];


useUserCreationDateForActivityQuery.fetcher = (client: GraphQLClient, variables: UserCreationDateForActivityQueryVariables, headers?: RequestInit['headers']) => fetcher<UserCreationDateForActivityQuery, UserCreationDateForActivityQueryVariables>(client, UserCreationDateForActivityDocument, variables, headers);
