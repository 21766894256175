import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type SubmitFeedbackMutationVariables = Types.Exact<{
  idea: Types.Scalars['String']['input'];
  source: Types.Scalars['feedback_source']['input'];
  sentiment?: Types.InputMaybe<Types.Feedback_Sentiment>;
  associated_object?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SubmitFeedbackMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly insert_feedback_one?: Types.Maybe<(
    { readonly __typename?: 'feedback' }
    & Pick<Types.Feedback, 'id'>
  )> }
);



export const SubmitFeedbackDocument = `
    mutation SubmitFeedback($idea: String!, $source: feedback_source!, $sentiment: feedback_sentiment, $associated_object: String) {
  insert_feedback_one(
    object: {idea: $idea, source: $source, associated_object: $associated_object, sentiment: $sentiment}
  ) {
    id
  }
}
    `;

export const useSubmitFeedbackMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SubmitFeedbackMutation, TError, SubmitFeedbackMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SubmitFeedbackMutation, TError, SubmitFeedbackMutationVariables, TContext>(
      ['SubmitFeedback'],
      (variables?: SubmitFeedbackMutationVariables) => fetcher<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>(client, SubmitFeedbackDocument, variables, headers)(),
      options
    )};

useSubmitFeedbackMutation.getKey = () => ['SubmitFeedback'];


useSubmitFeedbackMutation.fetcher = (client: GraphQLClient, variables: SubmitFeedbackMutationVariables, headers?: RequestInit['headers']) => fetcher<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>(client, SubmitFeedbackDocument, variables, headers);
