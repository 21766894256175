import type { DailyCall, DailyPendingRoomInfo, DailyRoomInfo } from '@daily-co/daily-js';

import { useGenerateCallAccessTokenMutation } from '@/client/features/calls/operations/generated/generate-call-access-token.user';
import { useSetChannelPresenceMutation } from '@/client/features/channels/operations/generated/set-channel-presence.user';
import { Channel_User_Status } from '@/generated/graphql/global-types.admin';
import { DAILY_URL } from '@/legacy/lib/constants';
import { wrapFetcher } from '@/shared/graphql/client';

const setChannelPresence = wrapFetcher(useSetChannelPresenceMutation.fetcher);
const generateCallAccessToken = wrapFetcher(useGenerateCallAccessTokenMutation.fetcher);

export interface JoinBreakoutCallProps {
  audioInputDeviceId?: null | string;
  audioOutputDeviceId?: null | string;
  callAccessToken?: null | string;
  callObject: DailyCall | null;
  roomId: string;
  breakoutChannelId: string;
  startMuted?: boolean;
  startVideo?: boolean;
  startAudio?: boolean;
  videoInputDeviceId?: null | string;
}

export const joinBreakoutCall = async ({
  audioInputDeviceId,
  audioOutputDeviceId,
  callAccessToken,
  callObject,
  roomId,
  breakoutChannelId,
  startMuted,
  startVideo = false,
  startAudio = true,
  videoInputDeviceId,
}: JoinBreakoutCallProps) => {
  if (!callObject || !roomId || !breakoutChannelId) {
    throw new Error('invalid-argument');
  }
  const room = await callObject.room();

  await tryLeaveCall(callObject, room, roomId);
  await setChannelPresence({ channelId: breakoutChannelId, status: Channel_User_Status.Active });

  if (!callAccessToken) {
    const generateResponse = await generateCallAccessToken({
      roomId,
      channelId: breakoutChannelId,
      startMuted,
    });

    callAccessToken = generateResponse.callAccessToken?.token;

    // Already checked this, but there's a potential race condition in that we may have joined a call whilst our access
    // token request was in flight.
    await tryLeaveCall(callObject, room, roomId);
  }
  if (!callAccessToken) {
    throw new Error('token-error');
  }
  if (audioOutputDeviceId) {
    void callObject.setOutputDeviceAsync({
      outputDeviceId: audioOutputDeviceId,
    });
  }
  await callObject.preAuth({ url: `${DAILY_URL}${roomId}`, token: callAccessToken });
  await callObject.join({
    url: `${DAILY_URL}${roomId}`,
    token: callAccessToken,
    subscribeToTracksAutomatically: false,
    inputSettings: {
      audio: {
        processor: {
          type: 'noise-cancellation',
        },
      },
    },
    startAudioOff: startMuted || !startAudio, // Setting these to "true" to prevent platform permission issues.
    startVideoOff: !startVideo,
    audioSource: audioInputDeviceId ?? true,
    videoSource: videoInputDeviceId ?? true,
  });
};

function isDailyRoomInfo(room: DailyPendingRoomInfo | DailyRoomInfo | null): room is DailyRoomInfo {
  return (room as DailyRoomInfo)?.name !== undefined;
}

async function tryLeaveCall(callObject: DailyCall, room: DailyPendingRoomInfo | DailyRoomInfo | null, roomId: string) {
  if (!isDailyRoomInfo(room)) {
    return;
  }
  if (room?.name !== roomId) {
    await callObject.leave();
  }
}
