import type { Channels } from '@/generated/graphql/global-types.user';

export enum ChannelEventType {
  JoinVoice = 'join_voice',
  ExitVoice = 'exit_voice',
  SwapFromVoice = 'swap_from_voice',
  SwapToVoice = 'swap_to_voice',
  StartSpeaking = 'start_speaking',
  StopSpeaking = 'stop_speaking',
  HandRaised = 'hand_raised',
}

export type ChannelEventChannel = Pick<Channels, 'id' | 'name' | 'type'>;

export type BaseChannelEvent<T extends ChannelEventType> = {
  type: T;
};

export type BaseUserChannelEvent<T extends ChannelEventType> = BaseChannelEvent<T> & {
  userId: string;
};

export type JoinVoiceChannelEvent = BaseUserChannelEvent<ChannelEventType.JoinVoice> & {
  channel: ChannelEventChannel;
};

export type ExitVoiceChannelEvent = BaseUserChannelEvent<ChannelEventType.ExitVoice> & {
  channel: ChannelEventChannel;
};

export type SwapFromVoiceChannelEvent = BaseUserChannelEvent<ChannelEventType.SwapFromVoice> & {
  fromChannel: ChannelEventChannel;
};

export type SwapToVoiceChannelEvent = BaseUserChannelEvent<ChannelEventType.SwapToVoice> & {
  toChannel: ChannelEventChannel;
};

export type HandRaisedChannelEvent = BaseUserChannelEvent<ChannelEventType.HandRaised> & {
  channel: ChannelEventChannel;
};

export type StartSpeakingChannelEvent = BaseUserChannelEvent<ChannelEventType.StartSpeaking>;

export type StopSpeakingChannelEvent = BaseUserChannelEvent<ChannelEventType.StopSpeaking>;

export type ChannelEvent =
  | JoinVoiceChannelEvent
  | ExitVoiceChannelEvent
  | SwapFromVoiceChannelEvent
  | SwapToVoiceChannelEvent
  | StartSpeakingChannelEvent
  | StopSpeakingChannelEvent
  | HandRaisedChannelEvent;
