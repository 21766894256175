import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const TooltipProvider = TooltipPrimitive.Provider;

export const Tooltip = TooltipPrimitive.Root;

export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = ({
  children,
  className,
  dark,
  ...props
}: TooltipPrimitive.TooltipContentProps & { dark?: boolean | undefined }): JSX.Element => {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        className={twMerge(
          clsx(
            'z-[101] rounded-lg border border-control-stroke shadow-sm text-center',
            dark == null && 'bg-page-base',
            dark && 'bg-page-base-dark',
            dark === false && 'bg-page-base-dark'
          ),
          className
        )}
        {...props}
      >
        <div
          className={clsx(
            'px-3 py-2 text-xs font-medium',
            dark == null && 'text-primary',
            dark && 'text-primary-dark',
            dark === false && 'text-primary-light'
          )}
        >
          {children}
        </div>
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  );
};
export const TooltipArrow = ({
  dark,
  className,
  ...props
}: TooltipPrimitive.TooltipArrowProps & {
  dark?: boolean | undefined;
}): JSX.Element => (
  <TooltipPrimitive.Arrow
    width={8}
    height={4}
    className={clsx(
      dark == null && 'fill-page-base',
      dark && 'fill-page-base-dark',
      dark === false && 'fill-page-base-light',
      className
    )}
    {...props}
  />
);
